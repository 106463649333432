.viewDeviceDrawer {
	.ag-grid-wrapper.ag-theme-alpine {
		height: calc(100vh - 222px);
	     .ag-root-wrapper {

			border: none;
			background-color: @white;

			.ag-header {
				border-bottom-color: @line-color;
				background-color: @white;

				.ag-pinned-left-header {
					border: none;
					background-color: @white;
				}

				.ag-header-cell {
					border-top: none;
					background-color: @white;

				}

				.ag-input-wrapper {
					.ag-input-field-input {
						background-color: @input-color;
						border: none;
						height: 30px;
						border-radius: 3px;
					}
				}

				.ag-header-cell-label {
					.ag-header-cell-text {
						.boldFont();
						font-size: 15px;
						color: @font-color;
					}
				}

				.ag-floating-filter-button {
					button {
						&:hover {
							cursor: pointer;

							span {
								color: @primary-color;
							}

						}

					}
				}
			}

			.ag-root-wrapper-body {


				.ag-header-cell {
					.ag-header-cell-resize {
						&:after {
							display: none;
						}
					}
				}

			}

			.ag-pinned-right-header {
				border-left: none;
			}

			.ag-body-viewport {

				// padding-top:10px;
				.ag-row {
					border-bottom-color: @line-color;

					.ag-cell {
						border-right: none;
						line-height: 50px;
						border-left: none;
						.regulerFont();
					}
				}


			}
		}


	}
	.ant-picker-clear{
		right:10px !important;
	}
}

.publishLayout{
	.imgCol{
		.ant-image{
			height:165px;
			// width:289px;
			img{
				height:100%;
				width:100%;
			}
		}
	}
}

.publishModal{
   .ant-select{
	// background-color: #393e42 !important;
	
	border: 1px solid #f4f5f7;
	.ant-select-selector{
     height: 48px !important;
	 background-color:@input-color;	 
	}
   }
  .ant-select-selector{
	.ant-select-selection-overflow{
		.ant-select-selection-overflow-item {
			.ant-select-selection-search{
				.ant-select-selection-search-input{
					background-color:transparent !important;
					padding:0px 0px !important;
				}
			}
		}
	}
  }
}

.dark-mode {

	// .ant-picker-dropdown-placement-bottomLeft, .ant-picker-dropdown-placement-bottomRight{
	// 	.ant-picker-body{
	// 		.ant-picker-content{
	// 			tbody{
	// 				tr{
	// 					.ant-picker-cell-disabled{
	// 				       &::before{
	// 						background-color:#000;
	// 					   }
	// 					}
	// 				}
	// 			}
	// 		}
	// 	}
	// }
	.deleteModal {
		h3 {
			color: @white;
			font-size: 17px;
		}
	}

	.viewDeviceDrawer {
		.ag-grid-wrapper {
			.ag-root-wrapper-body {
				background-color: #444a4f;
				padding: 0px 10px;

				.ag-header {
					background-color: #444a4f;
					border-bottom: none;

					.ag-header-cell {
						background-color: #444a4f;

						.ag-header-cell-text {
							color: @white;
						}
					}

					.ag-input-wrapper {
						.ag-input-field-input {
							background-color: @dark-even-row;
							color: #fff;
						}
					}
					.ag-icon-filter{
						color:@white;
					}
				}

				.ag-body-viewport {
					.ag-row-even {
						background-color: @dark-even-row;
					}

					.ag-row-odd {
						background-color: @dark-odd-row;
					}

					.ag-row {
						border-bottom-color: @dark-border;

						.ag-cell {
							color: @white;
						}
					}
				}
				.ag-overlay{
					.ag-overlay-panel{
						.ag-overlay-wrapper{
							color:@white;
						}
					}
				}

			}

			.ag-paging-panel {
				background-color: #444a4f;

				.ag-paging-row-summary-panel {
					color: @white;
				}

				.ag-paging-page-summary-panel {
					color: @white;

					.ag-paging-button {
						color: @white;
					}
				}
			}

			.ag-floating-filter-button {
				button {
					span {
						color: @white;
					}
				}
			}
		}
		.ant-form-item{
			.ant-form-item-row{
				.ant-form-item-control-input{
				.ant-form-item-control-input-content{
					.ant-picker{
						background-color:@dark-even-row;
						.ant-picker-suffix{
							svg{
								path{
									fill:@white;
								}
							}
						}
						.ant-picker-range-separator{
							svg{
								path{
									fill:@white;
								}
							}
						}
						input{
							color:@white !important;
						}
				
					}
				}
				}
			}
		}
	}

	.deactivateModal {
		.ant-modal-body{
			.ant-row{
				align-items:center;
			}
		}
		h3 {
			color: @white;
		}
	}

	.layoutDrawer {


		.ant-upload-list-item {
			background: #393e42;
			color: white;
		}

		.ant-upload-list-item-card-actions {
			button {
				.anticon {
					svg {
						path {
							fill: @white;
						}
					}
				}
			}
		}

	}
	.ant-picker-panels{
		.ant-picker-date-panel{
			.ant-picker-body{
				.ant-picker-content{
					tbody{
						tr{
							.ant-picker-cell-in-range{
								&:before{
									background-color:#6c6046;
								}
								.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single){
									&:before{
										background-color:#6c6046;
									}
								}
								
							}
						}
					}
				}
			}
		}
	}
}


.deleteModal {
	width: 550px !important;

	.ant-modal-header {
		border-top: 3px solid @error-color !important;
	}

	.ant-col-4 {
		@media screen and (max-width:400px) {
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	.ant-col-20 {
		@media screen and (max-width:400px) {
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex: 0 0 100%;
			max-width: 100%;
		}
	}


	h3 {
		.regulerFont();
		color: @font-color;
		font-size: 16px;
		margin-bottom: 15px;
		letter-spacing: 0.5px;
	}

	.ant-modal-footer {
		justify-content: center !important;

		.deleteBtn {
			background-color: @error-color;
			color: @white;
			border: 1px solid transparent !important;

			&:hover {
				span {
					color: @white !important;
				}
			}
		}
	}

}

.layoutDrawer {

	#addEditLayoutForm {
		@media screen and(max-width:850px) {
			.ant-col-6 {
				display: block;
				flex: 0 0 50%;
				max-width: 50%;

				.ant-radio-group {
					justify-content: flex-start;
				}
			}

			.ant-col-18 {
				display: block;
				flex: 0 0 50%;
				max-width: 50%;

				.ant-col-12 {
					display: block;
					flex: 0 0 100%;
					max-width: 100%;
				}
			}
		}

		@media screen and(max-width:600px) {
			.ant-col-6 {
				display: block;
				flex: 0 0 100%;
				max-width: 100%;

				.ant-radio-group {
					justify-content: flex-start;
				}
			}

			.ant-col-18 {
				display: block;
				flex: 0 0 100%;
				max-width: 100%;

				.ant-col-12 {
					display: block;
					flex: 0 0 100%;
					max-width: 100%;
				}
			}

		}

		@media screen and(max-width:480px) {
			.layoutOptions {
				.ant-col-12 {
					display: block;
					flex: 0 0 100%;
					max-width: 100%;
				}
			}

			#addEditStyleLayoutForm {
				.ant-col-12 {
					display: block;
					flex: 0 0 100%;
					max-width: 100%;
				}
			}
		}
	}
}

#addEditStyleLayoutForm {
	@media screen and(max-width:480px) {


		.ant-col-12 {
			display: block;
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
}

.addLicense {
	.ant-modal-footer {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		button {

			padding: 6px 10px;
			border-radius: 3px;
			margin: 0px 12px 0px 0px;
		}

		.blackLineBtn {
			background-color: transparent;
			border: 1px solid #797979;
			.transition();

			span {
				color: @modal-font-color;
				.regulerFont();
				font-size: 16px;
				.transition();
			}

			&:hover {
				border: 1px solid @primary-color;

				span {
					color: @primary-color;
				}
			}
		}

		.orangeBgBtn {
			background-color: @primary-color;
			color: #fff;
			border: 1px solid transparent;
			.transition();

			span {
				color: @white;
				.regulerFont();
				font-size: 16px;
				.transition();
			}

			&:hover {
				border: 1px solid @primary-color;
				background-color: @white;

				span {
					color: @primary-color;
				}
			}
		}
	}

}

.editLicense {


	.deviceDetails {
		padding-left: 10px;
		padding-bottom: 10px;

		p {
			font-size: 15px;
			.boldFont();

			span {
				.regulerFont();
				color: @font-color;
			}
		}
	}

	.ant-radio-group {
		width: 100% !important;
		justify-content: flex-start !important;
	}
}

.scheduleDrawer {
	form {
		.ant-select-selector {
			.ant-select-selection-search input {
				background-color: transparent !important;
				padding: 0px 0px;
			}
		}

		@media screen and(max-width:600px) {
			.ant-col-12 {
				display: block;
				flex: 0 0 100%;
				max-width: 100%;
			}
			.ant-col-8 {
				display: block;
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
	}

	.ant-table-wrapper {
		.ant-table {
			.ant-table-content {
				.ant-table-tbody {
					.ant-table-cell {
						.ant-form-item {
							margin-bottom: 10px;
							margin-top: 10px;
						}
					}
				}
			}
		}
	}
	
}

.editUser {
	.ant-drawer-header {
		border-top: 5px solid @primary-color;
	}

	.ant-form-item-extra {
		margin-top: 12px;
	}

	.selectRoleType {
		display: flex;
		align-items: center;

		.ant-form-item {
			width: 100%;
		}

		button {
			width: 30%;
			margin-left: 15px;

			&.modifyBtn {
				background: transparent;
				padding: 4px 10px;
				border: 1px solid #000;
				height: 38px;
				border-radius: 3px;
				.transition();

				span {
					.regulerFont();
					color: #000;
					.transition();
				}

				&:hover {
					border: 1px solid @primary-color;

					span {
						color: @primary-color;

					}
				}
			}

			&.disibleModifyBtn {
				background: transparent;
				padding: 4px 10px;
				border: 1px solid #00000040;
				height: 38px;
				border-radius: 3px;
			}
		}

		.ant-form-item-extra {
			position: absolute;
			right: 10px;
			bottom: 2px;
		}
	}

	.permisionRow {
		padding-left: 10px;

		div {
			.regulerFont();
			// color:@primary-color;
			color: @font-color;
			font-size: 16px;
		}

	}

	.ant-checkbox-wrapper {
		margin: 5px;
	}

	.permissionCard {
		.ant-card-body {
			padding: 10px;
		}
	}
}


.deactivateModal {
	.ant-modal-body {


		.deactiveCol {
			display: flex;
			align-items: center;

			.ant-typography {
				width: calc(100% - 60px);
				.regulerFont();
				font-size: 16px;
				padding-left: 10px;

				span {
					.boldFont();
					padding-left: 5px;
				}
			}
		}
	}
}

.layoutDrawer {
	.ant-drawer-content-wrapper {
		@media screen and (max-width:1234px) {
			width: 100% !important;
		}
	}

	.ant-form-vertical {

		.ant-form-item-label {
			padding: 5px 0px !important;
		}

		.layoutForm {
			.layoutOptions {
				.ant-col-12 {
					padding-left: 0px !important;
					padding-right: 0px !important;
				}
			}

			.ant-form-item {
				margin-bottom: 12px;

				.ant-form-item-extra {
					padding-top: 5px;
				}
			}
		}
	}

}

#addEditStyleLayoutForm {
	.color-picker-input-design {
		padding: 0px 0px;
	}

}

#addEditLayoutForm {
	.ant-form-item {
		margin-bottom: 12px;
	}

	.ant-radio-group {
		.ant-radio-button-wrapper {
			padding: 0px 21px;
		}
	}
}

.editLayout {
	.anticon-delete {
		svg {
			&:hover {
				path {
					fill: @error-color;
				}
			}
		}
	}
}

.settingContainer {
	@media screen and (max-width:600px) {
		.ant-col-12 {
			display: block;
			flex: 0 0 100%;
			max-width: 100%;

			&.settingSaveBtn {
				text-align: start !important;
				margin-left: 0px !important;
			}
		}
	}
}

.scheduleForm {
	@media screen and (max-width:921px) {
		.ant-col-12 {
			display: block;
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
}

.scheduleCalender {
	.ant-picker-calendar {
		@media screen and (max-width:600px) {
			width: 100%;
			overflow: auto;
		}

		.ant-picker-calendar-year-select {
			.ant-select-selector {
				@media screen and (max-width:352px) {
					padding: 0px 5px;
				}
			}

		}
	}
}