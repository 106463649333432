.light__layout {
    .profileLayout {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 60px 0px 0px 0px;

        .ant-tabs {
            width: 50%;
             .ant-tabs-content-holder{
                height: calc(75vh - 40px);
                overflow: auto;
             }
            .ant-tabs-nav-wrap {
          
                padding:7px 0px;

                .ant-tabs-nav-list {
                    width: 100%;
                    .ant-tabs-tab {
                      justify-content: center;
                      width: -webkit-fill-available;
                        .ant-tabs-tab-btn {
                          
                            .regulerFont();
                            font-size: 16px;
                            color: @font-color;
                            text-align: center;
                            @media screen and (max-width:480px) {
                                font-size: 12px;
                            }
                        }

                        padding:15px 10px;
                        background-color: @white;
                        // border-top-left-radius: 20px;
                        // border-top-right-radius: 20px;

                        &:nth-child(2) {
                            margin: 0px 0px 0px 5px !important;
                        }

                        &.ant-tabs-tab-active {
                            // border: 1px solid @primary-color;
                            background: @primary-color;

                            .ant-tabs-tab-btn {
                                color: #fff;
                            }
                        }
                    }

                }
            }

            .ant-tabs-content-holder {
                #publishForm {
                  
                    background-color: @white;
                    padding: 30px 24px;
                    border-radius: 3px;
                    // box-shadow: 0px 0px 0px 1px @primary-color;

                    .iconRow {
                        justify-content: center;
                        .formIcon {
                            border: 1px solid @primary-color;
                            height: 108px;
                            width: 108px;
                            border-radius:50%;

                            svg {
                                path {
                                    fill: @primary-color;
                                }
                            }
                            .borderIcon{
                                // background: #fde4be;
                                background-color: #9dc8e98a;
                                /* padding: 25px 28px; */
                                border-radius: 50%;
                                height: 105px;
                                width: 105px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                border: 6px solid white;
                                @media screen and (max-width:600px) {
                                    height: 83px;
                                    width: 83px;
                                }
                                
                            }
                            @media screen and (max-width:600px) {
                                height: 85px;
                                width: 85px;
                            }
                        
                        }
                    }

                    .ant-form-item-label {
                        label {
                            color: @font-color;
                            .regulerFont();
                            font-size:16px;
                        }
                    }

                    .ant-form-item-control {
                        .ant-form-item-control-input-content {
                            input {
                                background-color: @input-color;
                                border: none;
                                height: 38px;
                                color: @font-color;
                                padding:0px 12px;
                                .regulerFont();
                                border: 1px solid #f4f5f7;
                                &:focus{
                                    border:1px solid @primary-color;
                                }
                            }
                            .ant-select-selector{
                                padding:0px 12px !important;
                                border:none;
                                .ant-select-selection-search{
                                    left:0 !important;
                                    right:0 !important;
                               
                                    input{
                                        height: 36px !important;
                                    
                                        padding: 0px 12px !important;
                                    }
                                }
                            }
                        }
                    }
                    .profileBtn{
                        display: flex;
                        justify-content: center;
                    }
                }

                #changePass {
               
                    background-color: @white;
                    padding:30px 24px;
                    border-radius: 3px;
                    // box-shadow: 0px 0px 0px 1px @primary-color;

                    .iconRow{
                         justify-content: center;
                         .formIcon {
                            border: 1px solid @primary-color;
                            height: 108px;
                            width: 108px;
                            border-radius:50%;

                            svg {
                                path {
                                    fill: @primary-color;
                                }
                            }
                            .borderIcon{
                                // background: #fde4be;
                                background-color: #9dc8e98a;
                                /* padding: 25px 28px; */
                                border-radius: 50%;
                                height: 105px;
                                width: 105px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                border: 6px solid white;
                                @media screen and (max-width:600px) {
                                    height: 83px;
                                    width: 83px;
                                }
                                
                            }
                            @media screen and (max-width:600px) {
                                height: 85px;
                                width: 85px;
                            }
                        
                        }
                      }

                    .ant-form-item-label {
                        label {
                            color: @font-color;
                            .regulerFont();
                            font-size: 16px;
                        }
                    }

                    .ant-form-item-control {
                        .ant-form-item-control-input-content {
                            .ant-input-affix-wrapper{
                                background-color: @input-color;
                                border: 1px solid #f4f5f7;
                                border-radius:3px;
                            }
                            input {
                              
                                border: none;
                                height: 38px;
                                color: @font-color;
                                .regulerFont();
                                padding:0px 12px;
                            
                            }
                            .ant-input-affix-wrapper-focused, .ant-input-affix-wrapper-status-error{
                                input{
                                    background-color: @input-color;
                                    
                                }
                            }
                            .ant-input-suffix{
                                .anticon{
                                    svg{
                                        height:17px;
                                        width:17px;
                                        .transition();
                                        path{
                                            fill:#000;
                                        }
                                        &:hover{
                                       
                                                path{
                                                    fill:@primary-color;
                                                }
                                            
                                        }
                                    }
                                   
                                }
                            }
                   
                        }
                    }
                    .profileBtn{
                        display: flex;
                        justify-content: center;
                    }
                }
            }

            .ant-tabs-ink-bar {
                height: 0px !important;
                width: 0 !important;
            }

            &.ant-tabs-tab+.ant-tabs-tab {
                margin: 0 0 0 0px !important;
            }

            .ant-tabs-nav {
                margin: 0 0 0px 0 !important;
            }
            @media screen and (max-width:1280px) {
                width: 65%; 
            }
            @media screen and (max-width:768px) {
                width: 100%; 
            }
        }
    }


}

.dark__layout {
    .profileLayout{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 60px 0px 0px 0px;
    .ant-tabs {
        width: 50%;
        .ant-tabs-nav {
            &:before {
                border-bottom: 1px solid transparent;
            }
        }
        .ant-tabs-content-holder{
            height: calc(75vh - 40px);
            overflow: auto;
         }
        .ant-tabs-nav-wrap {
      
            padding:7px 0px;
            .ant-tabs-nav-list {
                width: 100%;
                .ant-tabs-tab {
                    justify-content: center;
                    width: 49%;
                    .ant-tabs-tab-btn {
                       
                            .regulerFont();
                            font-size: 16px;
                            color:@primary-color;
                            text-align: center;
                            @media screen and (max-width:480px) {
                                font-size: 12px;
                            }
                    }

                    padding:15px 10px;
                    background-color: @dark-odd-row;
                    // border-top-left-radius: 20px;
                    // border-top-right-radius: 20px;

                    &:nth-child(2) {
                        margin: 0px 0px 0px 10px !important;
                    }

                    &.ant-tabs-tab-active {
                        // border: 1px solid @primary-color;
                        background: @primary-color;

                        .ant-tabs-tab-btn {
                            color:@white;
                        }
                    }
                }

            }
            .ant-tabs-nav-operations{
                svg{
                    path{
                        fill:@white;
                    }
                }
            }
        }

        .ant-tabs-content-holder {
            #publishForm {
        
                background-color: @dark-even-row;
                padding:30px 24px;
                border-radius: 3px;
                
                .iconRow {
                    justify-content: center;
                    .formIcon {
                        border: 1px solid @primary-color;
                        height: 108px;
                        width: 108px;
                        border-radius:50%;

                        svg {
                            path {
                                fill: @primary-color;
                            }
                        }
                        .borderIcon{
                            background:#3e5c74d2;
                            /* padding: 25px 28px; */
                            border-radius: 50%;
                            height: 105px;
                            width: 105px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            border: 6px solid #000;
                            @media screen and (max-width:600px) {
                                height: 83px;
                                width: 83px;
                            }
                            
                        }
                        @media screen and (max-width:600px) {
                            height: 85px;
                            width: 85px;
                        }

                    
                    }
                }

                .ant-form-item-label {
                    label {
                        color: @white;
                        .regulerFont();
                        font-size:16px;
                    }
                }

                .ant-form-item-control {
                    .ant-form-item-control-input-content {
                        input {
                            background-color: @dark-odd-row;
                            border: none;
                            height: 38px;
                            color: @white;
                            .regulerFont();
                            border: 1px solid rgba(189, 185, 181, 0.2); 
                            &:focus{
                                border:1px solid @primary-color;
                            }
                        }
                        .ant-select{
                            .ant-select-selector{
                            padding:0px 12px !important;
                            background-color: @dark-odd-row;
                            color:@white;
                            border:none;
                            .ant-select-selection-search{
                                left:0 !important;
                                right:0 !important;
                           .ant-select-selection-item{
                            color:@white;
                           }
                                input{
                                    height: 36px !important;
                                    color:@white;
                                    padding: 0px 12px !important;
                                }
                            }
                         
                        }
                        .ant-select-arrow{
                            .anticon{
                                svg{
                                    path{fill:@white;}
                                }
                            }
                        }
                    }
                    }
                }
                .profileBtn{
                    display: flex;
                    justify-content: center;
                    .disableBtn {
                        background-color: #d9d9d9 !important;
                     padding: 6px 30px;
                     height: 40px;
     
                     span {
                         color: @label-color;
                     }
     
                 }
                }
            }

            #changePass {
          
                background-color: @dark-even-row;
                padding:30px 24px;
                border-radius: 3px;
         
                

                .iconRow {
                    justify-content: center;
                    .formIcon {
                        border: 1px solid @primary-color;
                        height: 108px;
                        width: 108px;
                        border-radius:50%;

                        svg {
                            path {
                                fill: @primary-color;
                            }
                        }
                        .borderIcon{
                            background:#3e5c74d2;
                            /* padding: 25px 28px; */
                            border-radius: 50%;
                            height: 105px;
                            width: 105px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            border: 6px solid #000;
                            @media screen and (max-width:600px) {
                                height: 83px;
                                width: 83px;
                            }
                            
                        }
                        @media screen and (max-width:600px) {
                            height: 85px;
                            width: 85px;
                        }
                    
                    }
                }
                .ant-form-item-label {
                    label {
                        color: @white;
                        .regulerFont();
                        font-size:16px;
                    }
                }

                .ant-form-item-control {
                    .ant-form-item-control-input-content {
                        .ant-input-affix-wrapper{
                            background-color:@dark-odd-row;
                            border: 1px solid rgba(189, 185, 181, 0.2); 
                            border-radius:3px;
                        }
                        input {
                            background-color: @dark-odd-row;
            
                            height: 38px;
                            color: @white;
                            .regulerFont();
                        }
                    }
                }
                .profileBtn{
                    display: flex;
                    justify-content: center;
                    .disableBtn {
                           background-color: #d9d9d9 !important;
                        padding: 6px 30px;
                        height: 40px;
        
                        span {
                            color: @label-color;
                        }
        
                    }
        
                }
            }
        }

        .ant-tabs-ink-bar {
            height: 0px !important;
        }

        &.ant-tabs-tab+.ant-tabs-tab {
            margin: 0 0 0 0px !important;
        }

        .ant-tabs-nav {
            margin: 0 0 0px 0 !important;
        }
        @media screen and (max-width:1280px) {
            width: 65%; 
        }
        @media screen and (max-width:768px) {
            width: 100%; 
        }
    }
    }
}