.loginSection {
    .loginWrap {
        display: flex;
        flex-wrap: wrap;
        min-height: 100vh;

        .loginLogo {
            width: 55%;
            text-align: center;
            position: relative;

            .loginBanner {
                position: absolute;
                bottom: 0;
                left: 0;
                text-align: left;
                width: 100%;
                height: 100%;

                img {
                    max-width: 100%;
                    width: 100%;
                    height: 100%;
                    object-position: right bottom;
                    object-fit: cover;
                }
            }

            .logo {
                width: 100%;
                padding-top: 30px;
            }

            @media (max-width: 767px) {
                display: none;
            }
        }
    }
}

.loginForm {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: @primary-color;

    @media (max-width: 767px) {
        width: 100%;
    }

    .loginFormWrap {
        max-width: 434px;
        width: 100%;

        // padding: 40px 30px;
        // box-shadow: 0px 0px 14px -8px rgb(0 0 0 / 75%);
        @media (max-width: 767px) {
            max-width: 100%;
        }

        .resetPassword {
            button {
                span {
                    color: @primary-color !important;
                }
            }
        }

        .captchaInput {
            .captchaGen {
                position: relative;
            }
            .ant-input {
                padding: 0px 32px 0px 15px;
                font-style: italic;
                font-family: monospace;
            }

            .reloadIcon {
                position: absolute;
                cursor: pointer;
                top: 17px;
                right: 15px;

            }
        }
    }

    .login_extra_fields {
        display: flex;
        justify-content: space-between;

        input,
        span {
            color: @white;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            border-color: @white !important;
            top: -1px;
            left: -1px;
            width: 16px;
            height: 16px;
        }
    }

    .formTitle {
        margin-bottom: 30px;
        text-align: center;

        h2 {
            display: inline-block;
            font-size: 34px;
            font-weight: 600;
            margin: 0;
            color: @white;
        }
    }

    .ant-form {
        .ant-form-item {
            margin-bottom: 40px;

            .ant-input-suffix {
                color: @white;
                opacity: 0.8;

                .ant-input-password-icon {
                    color: @white;
                }
            }
        }

        .ant-form-item-row {
            position: relative;
        }

        .ant-form-item-label {
            overflow: visible;
            position: absolute;
            top: -12px;
            left: 20px;
            z-index: 99;
            background-color: @primary-color;
            padding: 0 5px;

            label {
                font-size: 15px;
                font-weight: 500;
                color: @white;
                letter-spacing: 0.5px;
            }
        }

        .ant-input {
            padding: 0 15px;
        }

        .ant-input,
        .ant-input-affix-wrapper {
            background-color: transparent !important;
            height: 55px;
            color: @white;
            border: 1px solid #ddd;
            border-radius: 5px;
            font-weight: 500;
        }

        .ant-input-affix-wrapper {
            .ant-input {
                border: 0;
                height: auto;
                border-radius: 0;
            }
        }

        .ant-input-affix-wrapper:focus,
        .ant-input-affix-wrapper-focused {
            box-shadow: none;
        }

        input:-internal-autofill-selected {
            background-color: transparent !important;
        }

        input::-webkit-input-placeholder {
            /* Edge */
            color: @white;
            opacity: .8;
        }

        input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: @white;
            opacity: .8;
        }

        input::placeholder {
            color: @white;
            opacity: .8;
        }

        .ant-input:focus,
        .ant-input-focused {
            box-shadow: none;
        }

        .ant-btn {
            font-size: 20px;
            font-weight: 500;
            background: #fff;
            border: 2px solid transparent;
            color: @primary-color;
            text-shadow: none;
            box-shadow: none;
            border-radius: 5px;
            height: auto;
            width: 100%;
            max-width: 100%;
            margin-top: 0;
            transition: all 0.5s ease;

            // &:hover {
            // 	transform: none;
            // 	color: @white;
            // 	background-color: @secondary-color;
            // }

            &.disableBtn {

                border-color: transparent;
                background: #f5f5f5;

                span {
                    color: @primary-color !important;
                }

            }

            &.activeBtn {
                span {
                    color: @primary-color !important;
                }

                &:hover {
                    background-color: @white;
                }
            }

        }
    }

    a {
        color: @white;

        &:hover {
            color: @black
        }
    }

    b {
        a {
            color: @black;
        }
    }
}

.socialLoginWrap{
    margin: 0 0 40px;
    > div{
        margin: 10px 0;
    }
}
.or{
    text-align: center;
    position: relative;
    margin-bottom: 50px;
    z-index: 9;
    &::after{
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        background-color: #ddd;
        width: 100%;
        height: 1px;
        z-index: -1;
    }
    span{
        font-size: 18px;
        padding: 3px 10px 6px;
        color: #000;
        background-color: #ddd;
        line-height: 1;
        border-radius: 5px;
    }
}