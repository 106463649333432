// .light__layout {

    .scheduleLayout {
        .ant-page-header-content {
            .fc-media-screen {
                background-color: @white;
                height: calc(87vh - 170px);

                .fc-header-toolbar {
                    margin: 0;
                    padding: 15px 15px 0;
                    .fc-toolbar-chunk {
                        .fc-button-group {

                            button {
                                display: flex;
                                align-items: center;
                                background-color: @primary-color;
                                border: 1px solid @white;
                                border-radius: 3px;
                                opacity: 1;
                                text-transform: capitalize;
                                padding: 7px 18px;
                                .transition();

                                .fc-icon {
                                    height: 22px;
                                    width: 22px;
                                    
                                    // padding:7px 0px;
                                    &.fc-icon-chevron-left {
                                        &:before {
                                            line-height: 22px;
                                        }
                                    }
                                }

                                &:hover {
                                    background-color:@dark-blue-color;
                                }

                                &.fc-button-active {
                                    border: 1px solid transparent;
                                    box-shadow: 0px 0px 0px 1px rgb(35, 33, 30, 0.5);
                                    background-color:@dark-blue-color;
                                }

                                &.fc-prev-button,
                                &.fc-next-button {
                                    padding: 7px 10px;

                                    @media screen and(max-width:600px) {
                                        padding: 5px 6px;

                                    }

                                }

                                @media screen and(max-width:600px) {
                                    padding: 7px 8px;
                                    font-size: 12px;
                                }
                            }

                        }

                        .fc-today-button {
                            padding: 7px 15px;
                            background-color: @primary-color;
                            border: white;
                            opacity: 1;
                            border-radius: 3px;
                            text-transform: capitalize;
                            border: 1px solid @white;
                            .transition();
                            cursor: pointer;
                            &:disabled{
                                opacity: 0.7;
                            }
                            &:hover {
                                background-color:@dark-blue-color;
                            }

                            @media screen and(max-width:600px) {
                                padding: 7px 8px;
                                font-size: 12px;
                                margin-left: 4px;
                            }
                        }

                        .fc-timeGridWeek-button {
                            padding: 7px 15px;
                            background-color: @primary-color;
                            border: white;
                            opacity: 1;
                            border-radius: 3px;
                            text-transform: capitalize;
                            border: 1px solid @white;
                            .transition();
                            cursor: pointer;

                            &:hover {
                                background-color:@dark-blue-color;

                            }

                            @media screen and(max-width:600px) {
                                padding: 7px 8px;
                                font-size: 12px;
                                margin-left: 4px;
                            }
                        }
                    }

                    .fc-toolbar-title {
                        .regulerFont();
                        font-size: 24px;
                        color: @font-color;

                        @media screen and(max-width:600px) {
                            font-size: 16px;
                        }
                    }
                }

                .fc-view-harness {
                    height: calc(82vh - 172px);

                    @media screen and(max-width:600px) {

                        width: 100%;
                        overflow-x: auto;

                    }

                }

                .fc-view {
                    background-color: @white;
                    padding: 15px;

                    @media screen and(max-width:600px) {
                        width: 560px;
                    }

                    table {
                        border: none;
                    }

                    .fc-scrollgrid {
                        height: 100%;

                        @media screen and(max-width:600px) {

                            width: 550px;
                            overflow-x: scroll;
                        }

                        .fc-scrollgrid-section {
                            th {
                                border: none;
                            }
                        }
                    }

                    table {
                        &.fc-scrollgrid {
                            table {
                                // border-left-style: solid;
                                // border-right-style: solid;
                                // border-top-style: solid;
                                // border-left-width: 1px;
                                // border-right-width: 1px;
                                // border-top-width: 1px;
                                border: none;

                                thead {
                                    background-color: @primary-color;

                                    tr {
                                        th {
                                            .fc-scrollgrid-sync-inner {
                                                a {
                                                    color: @white;
                                                }
                                            }
                                        }
                                    }
                                }

                            }

                            .fc-col-header-cell {
                                border-right: 1px solid @white;
                                // border-bottom: 1px solid @white;
                                // border-left: 1px solid @white;
                                border-top: 1px solid @white;
                            }

                            // .fc-col-header {
                            //     width:100% !important;
                            // }
                        //    tbody{
                        //     tr{
                        //        .fc-day {
                        //         .fc-timegrid-col-frame{
                        //             .fc-timegrid-col-events{
                        //                 .fc-timegrid-event-harness{
                        //                     .fc-event {
                        //                         // cursor:default !important;
                        //                     }
                        //                 }
                        //             }
                        //         }
                        //        }  
                        //     }
                        //    }
                          thead{
                            tr{
                                th{
                                    .fc-scroller-harness{
                                        .fc-scroller{
                                            .fc-col-header {
                                                width:100% !important;
                                            }

                                         
                                        }
                                    }
                                }
                            }
                          }
                          tbody{
                            tr{
                                td{
                                    .fc-scroller-harness{
                                        .fc-scroller{
                                            .fc-daygrid-body{
                                                width:100% !important;
                                                .fc-scrollgrid-sync-table{
                                                    width:100% !important;
                                                }
                                             
                                            }
                                        }
                                    }
                                }
                            }


                            .fc-scrollgrid-section-liquid{
                                td{
                                    .fc-timegrid-body{
                                        width:100% !important;
                                      .fc-timegrid-cols{
                                        table{
                                            width:100% !important;
                                            tbody{
                                                td{
                                                    .fc-timegrid-col-frame{
                                                        .fc-timegrid-col-events{
                                                            .fc-timegrid-event-harness{
                                                                .fc-event{
                                                                    .fc-event-main{
                                                                        div{
                                                                            margin: -4px 0px !important;
                                                                            height: 100%;
                                                                            padding: 0px 5px;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                      }
                                    }
                                }
                            }
                          
                        }
                        }




                    }
                }
            }
        }
    
        // .fc-view{
        //     table &.fc-scrollgrid{
        //             tbody{
        //                 tr &.fc-scrollgrid-section-body {
        //                     td {
        //                         .fc-timegrid-col-frame {
        //                             .fc-timegrid-col-events {
        //                                 .fc-timegrid-event-harness {
        //                                     // .fc-event {
        //                                     //     height: 50px;
        //                                     //     display: flex;
        //                                     //     align-items: center;
        //                                     //     justify-content: space-between;
        //                                     // }

        //                                     // .fc-event-main {
        //                                     //     span &.toolData {
        //                                     //         height: 50px;
        //                                     //         max-height: 100%;
        //                                     //     }
        //                                     // }
                                           
        //                                 }


        //                             }
        //                         }
        //                     }
        //                 }
        //             }
        //     }
        // }


    }
// }




.light__layout .scheduleLayout .ant-page-header-content .fc-media-screen .fc-view table.fc-scrollgrid tbody .fc-scrollgrid-section-liquid td .fc-timegrid-body .fc-timegrid-cols table tbody td .fc-timegrid-col-frame .fc-timegrid-col-events .fc-timegrid-event-harness .fc-event .fc-event-main p {
 height: 100%
}


.dark__layout {
    .scheduleLayout {
        .scheduleForm{
            .ant-select-focused{
                .ant-select-selector{
                    border:1px solid @primary-color !important;
                }
            }
        }
        .ant-page-header-content {
            .fc-media-screen {
                .fc-header-toolbar {
                    // padding: 0px 2px;
                    // flex-wrap: wrap;

                    .fc-toolbar-chunk {
                        .fc-button-group {

                            button {
                                // display: flex;
                                // align-items: center;
                                background-color: @dark-odd-row;
                                border-color: @dark-even-row;
                                box-shadow: none;
                                // border: 1px solid #999292;
                                // border-radius: 3px;
                                // opacity: 1;
                                // color: @primary-color;
                                // text-transform: capitalize;
                                // padding: 7px 18px;
                                .transition();

                                .fc-icon {
                                    height: 22px;
                                    width: 22px;
                                    color: @white;
                                    // padding:7px 0px;
                                    &.fc-icon-chevron-left {
                                        &:before {
                                            line-height: 22px;
                                        }
                                    }
                                }

                                &:hover, &.fc-button-active{
                                    background-color:@layout-sidebar-dark-background;
                                    color: @white;
                                    border: solid 1px @white;
                                    
                                }
                                &.fc-button-active{
                                border-color: @white;
                                position: relative;
                                z-index: 2;
                            }
                                // &.fc-button-active {
                                //     border: 1px solid transparent;
                                //     box-shadow: 0px 0px 0px 1px rgb(35, 33, 30, 0.5);
                                //     // background-color: @dark-blue-color;
                                //     color: @white;
                                // }

                                // &.fc-prev-button,
                                // &.fc-next-button {
                                //     padding: 7px 10px;

                                //     @media screen and(max-width:600px) {
                                //         padding: 5px 6px;

                                //     }

                                // }
                            }

                        }

                        // .fc-today-button {
                        //     padding: 7px 15px;
                        //     background-color: @dark-odd-row;
                        //     border: white;
                        //     opacity: 1;
                        //     color: @white;
                        //     border-radius: 3px;
                        //     text-transform: capitalize;
                        //     border: 1px solid #999292;
                        //     .transition();
                        //     cursor: pointer;

                        //     &:hover {
                        //         background-color:@dark-blue-color;
                        //         color: @white;
                        //     }

                        //     @media screen and(max-width:600px) {
                        //         padding: 7px 8px;
                        //         font-size: 12px;
                        //         margin-left: 4px;
                        //     }
                        // }

                        // .fc-timeGridWeek-button {
                        //     padding: 7px 15px;
                        //     background-color: @dark-odd-row;
                        //     border: white;
                        //     opacity: 1;
                        //     color: @primary-color;
                        //     border-radius: 3px;
                        //     text-transform: capitalize;
                        //     border: 1px solid #999292;
                        //     .transition();
                        //     cursor: pointer;

                        //     &:hover {
                        //         background-color:@dark-blue-color;
                        //         color: @white;
                        //     }

                        //     @media screen and(max-width:600px) {
                        //         padding: 7px 8px;
                        //         font-size: 12px;
                        //         margin-left: 4px;
                        //     }
                        // }
                    }

                    .fc-toolbar-title {
                        .regulerFont();
                        font-size: 24px;
                        color: @white;

                        @media screen and(max-width:600px) {
                            font-size: 16px;
                        }
                    }

                    .fc-timeGridWeek-view {
                        .fc-scrollgrid {
                            tbody {
                                .fc-scrollgrid-section-body {
                                    td {
                                        border: none;
                                    }

                                }
                            }
                        }
                    }

                }

                .fc-view-harness {
                    height: calc(82vh - 172px);

                    @media screen and(max-width:600px) {

                        width: 100%;
                        overflow-x: auto;

                    }
                }

                .fc-view {
                    background-color: @dark-even-row;
                    // padding: 15px;

                    a{
                        color: @white;
                    }

                    @media screen and(max-width:600px) {
                        width: 560px;
                    }


                    table {
                        border: none;
                    }

                    .fc-scrollgrid {
                        height: 100%;

                        @media screen and(max-width:600px) {

                            width: 550px;
                            overflow-x: scroll;
                        }


                        .fc-scrollgrid-section {
                            th {
                                border: none;
                            }
                        }
                    }

                    table {
                        &.fc-scrollgrid {
                            table {
                                // border-left-style: solid;
                                // border-right-style: solid;
                                // border-top-style: solid;
                                // border-left-width: 1px;
                                // border-right-width: 1px;
                                // border-top-width: 1px;
                                border: none;

                                thead {
                                    background-color: #444a4f;
                                    border: 1px solid @dark-border;

                                    tr {
                                        th {
                                            .fc-scrollgrid-sync-inner {
                                                a {
                                                    color: @white;
                                                }
                                            }
                                        }
                                    }
                                }

                                tbody {
                                    tr {
                                        td {
                                            border: 1px solid @dark-border;
                                            ;
                                            color: @white;
                                            // height: 50px;

                                            .fc-scrollgrid-sync-inner {
                                                .fc-daygrid-day-top {
                                                    a {
                                                        color: @white;
                                                    }
                                                }
                                            }

                                            .fc-scrollgrid-shrink-frame {
                                                .fc-scrollgrid-sync-inner {
                                                    color: @white;
                                                }

                                                .fc-scrollgrid-shrink-cushion {
                                                    color: @white;
                                                }
                                            }
                                        }
                                    }
                                }

                            }

                            .fc-col-header-cell {
                                border-right: 1px solid @dark-border;
                                border-bottom: 1px solid @dark-border;
                                border-left: 1px solid @dark-border;
                                border-top: 1px solid @dark-border;
                            }

                            // tbody{
                            //     tr{
                            //        .fc-day {
                            //         .fc-timegrid-col-frame{
                            //             .fc-timegrid-col-events{
                            //                 .fc-timegrid-event-harness{
                            //                     .fc-event {
                            //                         cursor:default !important;
                            //                     }
                            //                 }
                            //             }
                            //         }
                            //        }  
                            //     }
                            // }
                              thead{
                                tr{
                                    th{
                                        .fc-scroller-harness{
                                            .fc-scroller{
                                                .fc-col-header {
                                                    width:100% !important;
                                                }
    
                                             
                                            }
                                        }
                                    }
                                }
                              }
                              tbody{
                                tr{
                                    td{
                                        .fc-scroller-harness{
                                            .fc-scroller{
                                                .fc-daygrid-body{
                                                    width:100% !important;
                                                    .fc-scrollgrid-sync-table{
                                                        width:100% !important;
                                                    }
                                                 
                                                }
                                            }
                                        }
                                    }
                                }
    
    
                                .fc-scrollgrid-section-liquid{
                                    td{
                                        .fc-timegrid-body{
                                            width:100% !important;
                                          .fc-timegrid-cols{
                                            table{
                                                width:100% !important;
                                                tbody{
                                                    td{
                                                        .fc-timegrid-col-frame{
                                                            .fc-timegrid-col-events{
                                                                .fc-timegrid-event-harness{
                                                                    .fc-event{
                                                                        .fc-event-main{
                                                                            div{
                                                                                margin:-4px 0px !important;
                                                                                height: 100%;
                                                                                padding: 0px 5px;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                          }
                                        }
                                    }
                                }
                              
                            }
                        }

                    }
                }
            }
        }
        .ant-page-header-content{
            .fc-media-screen {
                background-color: @dark-even-row;
            }
            .fc-media-screen{
                .fc-header-toolbar{
                    .fc-toolbar-chunk{
                        .fc-today-button{
                            background-color: @dark-odd-row;
                            border-color: @dark-even-row;
                        }
                    }
                }
            }
        }

    }
}

.dark__layout .scheduleLayout .ant-page-header-content .fc-media-screen .fc-view table.fc-scrollgrid tbody .fc-scrollgrid-section-liquid td .fc-timegrid-body .fc-timegrid-cols table tbody td .fc-timegrid-col-frame .fc-timegrid-col-events .fc-timegrid-event-harness .fc-event .fc-event-main p {
    height: 100%
   }


