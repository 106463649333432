img {
  max-width: 100%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: @text-color;
}

html,
body {
  font-size: 62.5%;

  .ant-layout {
    .ag-body-viewport {
      .ag-row-even {
        .transitionControl(0s, ease-in-out);
      }

      .ag-row-odd {
        .transitionControl(0s, ease-in-out);
      }
    }

    .ag-grid-wrapper.ag-theme-alpine {
      .ag-root-wrapper {
        .transition();

        .ag-header {
          .transition();

          .ag-header-container {
            .ag-header-cell {
              // .transition();
              .ag-floating-filter-button-button {
                .transition();
                cursor: pointer;
              }
            }
          }
        }

        .action-column {
          .ant-btn {
            svg {
              .transition();
            }
          }
        }
      }
    }
  }
}

// * {
// 	scrollbar-color: #b5b8bb #e9e9e9;
// 	scrollbar-width: thin;

// 	::-webkit-scrollbar {
// 		-webkit-appearance: none;
// 		width: 8px;
// 		height: 8px;
// 	}

// 	::-webkit-scrollbar-track {
// 		background: #e9e9e9;
// 	}

// 	::-webkit-scrollbar-thumb {
// 		background: #b5b8bb;
// 		transition: all 0.3s ease-in-out;

// 		&:hover {
// 			background: #93999e;
// 		}
// 	}
// }

html body {
  font-size: 1.5rem;
  color: @black;
  font-family: @primary-font;
  font-weight: normal;
  margin: 0px;
  padding: 0px;
  width: 100%;
  overflow-x: hidden;
  min-width: 320px;
  line-height: normal;
}

.light-mode {
  .ant-picker-dropdown {
    .ant-picker-content {
      width: 160px !important;
      max-width: 100%;
    }
  }

  .ant-picker-clear {
    right: -10px !important;
  }

  .ant-tooltip {
    .ant-tooltip-arrow {
      .ant-tooltip-arrow-content {
        &:before {
          background: linear-gradient(
            to right bottom,
            rgba(29, 118, 187, 0.7),
            rgba(29, 118, 187, 0.7)
          ) !important;
        }
      }
    }

    .ant-tooltip-inner {
      background-color: rgba(29, 118, 187, 0.7);

      span {
        display: flex;
        align-items: center;
        width: auto;
      }

      .extracontent {
        display: flex;
        align-items: center;
        padding: 0px 5px;

        .sidebarArrowIcon {
          align-items: center;
          display: flex;
          padding: 0px 2px;

          svg {
            path {
              fill: @white;
            }
          }
        }
      }
    }
  }

  .ant-image-preview-root {
    &.Portrait {
      .ant-image-preview-wrap {
        &:has(img.ant-image-preview-img) {
          img {
            width: auto;
            max-width: 60dvw;
            height: 60%;
            max-height: 60dvh;
          }
        }
      }
    }

    &.Landscape {
      .ant-image-preview-wrap {
        &:has(img.ant-image-preview-img) {
          img {
            width: 60%;
            max-width: 60dvw;
            height: auto;
            max-height: 60dvh;
          }
        }
      }
    }
  }
}

// .light__layout {
.main__app__content {
  // padding: 20px 20px 0px;
  padding: 10px 35px;
  background-color: @layout-bg-color;
  .transition();
  overflow-y: auto;

  .ant-page-header-heading-extra {
    display: flex;
    flex-wrap: wrap;

    .ant-space-horizontal {
      flex-wrap: wrap;

      @media screen and (max-width: 480px) {
        .ant-space-item {
          button {
            padding: 6px 22px;

            span {
              font-size: 12px;
            }
          }
        }
      }

      @media screen and (max-width: 467px) {
        .ant-space-item {
          button {
            padding: 6px 9px;
          }
        }
      }

      @media screen and (max-width: 400px) {
        .ant-space-item {
          button {
            padding: 6px 10px;
          }
        }
      }
    }
  }

  .dashBoardWrap {
    h1 {
      font-size: 22px;
      .regulerFont();
      color: @font-color;
    }

    height: calc(100vh - 147px);
  }

  .ant-breadcrumb-separator {
    svg {
      transform: rotate(180deg);

      path {
        fill: #c2c4c6;
      }
    }
  }

  .ant-page-header-heading-left {
    display: flex;
    align-items: center;
    margin: 4px 0;

    .ant-page-header-heading-title {
      order: 2;
      display: inline-flex;
    }
  }

  @media screen and (max-width: 400px) {
    padding: 10px 15px;
  }

  .scheduleLayout {
    .listIcon {
      position: relative;
      Button {
        margin: 10px;
      }
      .selected {
        color: #45ccf5;
        border-color: #45ccf5;
      }
      .ant-select {
        position: absolute;
        right: 10px;
      }
    }
    .ant-select {
      border: 1px solid #e9e9e9;
    }

    .scheduling-calender {
      flex-grow: 1;
      .ant-row {
        margin: 0 !important;
        justify-content: end !important;
      }

      .ant-select {
        position: static !important;
      }

      .scheduleLayout .listIcon .ant-select {
        position: unset !important;
      }

      .ant-form-vertical .ant-form-item-label {
        display: none !important;
      }

      .ant-form-item {
        margin-top: 10px;
        margin-bottom: 0 !important;
      }

      .ant-col {
        padding: 0 !important;
      }

      .ant-select-selector {
        height: 32px;

        .ant-select-selection-item {
          line-height: 32px;
        }
      }

      #device_id {
        height: 32px;
      }
    }
  }
  // }

  footer {
    &.ant-layout-footer {
      padding: 15px 35px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      box-shadow: 0px 8px 20px 3px rgba(170, 170, 185, 0.3);

      div {
        .regulerFont();
        font-size: 14px;
        color: @font-color;
        // letter-spacing: 0.5px;

        @media screen and (max-width: 768px) {
          font-size: 12px;
        }

        @media screen and (max-width: 350px) {
          font-size: 8px;
        }
      }

      @media screen and (max-width: 600px) {
        // justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      @media screen and (max-width: 400px) {
        padding: 15px 15px;
      }

      .footerLink {
        color: @dark-blue-color;
        .transition();

        &:hover {
          color: #5694c4;
        }
      }

      .otherLink {
        a {
          color: @dark-blue-color;

          &:hover {
            color: #5694c4;
          }
        }
      }
    }
  }

  .ant-page-header {
    .ant-page-header-heading-left {
      .ant-page-header-heading-title {
        font-size: 22px;
        .regulerFont();
        color: @font-color;

        @media screen and (max-width: 350px) {
          font-size: 20px;
        }
      }
    }

    .ant-page-header-heading-extra {
      .pageSelectBtn {
        .ant-select-selector {
          height: 42px;
          border-radius: 3px;
          background-color: @white;
          border: 1px solid transparent;
          .transition();

          .ant-select-selection-item {
            color: @header-font-color;
            line-height: 40px;
            font-size: 14px;

            font-family: @primary-font;
            border: none;
            .transition();

            @media screen and (max-width: 480px) {
              line-height: 35px;
              font-size: 12px;
            }

            @media screen and (max-width: 400px) {
              line-height: 32px;
              font-size: 12px;
            }
          }

          &:hover {
            .ant-select-selection-item {
              color: @primary-color;

              @media screen and (max-width: 400px) {
                padding-right: 15px;
              }
            }

            border: 1px solid @primary-color;
            border-radius: 3px;
          }

          @media screen and (max-width: 480px) {
            height: 35px;
            padding: 0px 30px;
          }

          @media screen and (max-width: 467px) {
            height: 35px;
            padding: 0px 10px;
          }
        }
      }
    }
  }

  .ant-page-header-content {
    .ag-grid-wrapper.ag-theme-alpine {
      height: calc(100vh - 250px) !important;

      .ag-root-wrapper {
        // height: calc(100vh - 262px);
        border: none;
        background-color: @white;
        border-radius: 3px;

        .ag-header {
          border-bottom-color: @line-color;
          background-color: @white;

          .ag-pinned-left-header {
            border: none;
            background-color: @white;
          }

          .ag-header-cell {
            border-top: none;
            // background-color: @white;
            height: 40px;

            .ag-floating-filter-button-button {
              .transition();
              cursor: pointer;

              &:hover {
                color: @primary-color;
              }
            }
          }

          .ag-input-wrapper {
            .ag-input-field-input {
              background-color: @ag-input-color;
              transition: none;
              border: none;
              height: 30px;
              border-radius: 3px;
              .transition();
            }
          }

          .ag-header-cell-label {
            .ag-header-cell-text {
              .boldFont();
              font-size: 15px;
              color: @font-color;

              @media screen and (max-width: 400px) {
                font-size: 13px;
              }
            }
          }

          .ag-header-row {
            &:after {
              background-color: transparent;
              content: none;
            }
          }
        }

        .ag-root-wrapper-body {
          padding: 10px 25px;

          .ag-header-cell {
            .ag-header-cell-resize {
              &:after {
                display: none;
              }
            }
          }
        }

        .ag-pinned-right-header {
          border-left: none;
        }

        .ag-body-viewport {
          // padding-top:10px;
          .ag-row {
            border-bottom-color: @line-color;

            .ag-cell {
              border-right: none;
              line-height: 38px;
              border-left: none;
              .regulerFont();
              text-align: left;

              .ant-image {
                height: 100%;

                // width:100%;
                .ant-image-img {
                  height: 100%;
                }

                .ant-image-mask-info {
                  font-size: 0px;

                  .anticon-eye {
                    font-size: 15px;
                    margin-top: 17px;
                  }
                }
              }
            }
          }

          .ag-pinned-right-cols-container {
            .ag-row {
              .ag-cell {
                width: 230px;
              }
            }
          }

          .statusIcon {
            line-height: 3.5;

            .statusOnIcon {
              height: 20px;
              width: 20px;
              .transition();

              path {
                fill: #f4516c;
              }

              // &:hover{
              // 	path{
              // 		fill:@dark-blue-color
              // 	}
              // }
            }

            .statusOffIcon {
              height: 20px;
              width: 20px;
              .transition();

              path {
                fill: #34bfa3;
              }
            }
          }
        }

        .action-column {
          .ant-btn {
            line-height: 3;

            svg {
              height: 16px;
              width: 16px;
              .transition();

              path {
                fill: @svg-color;
              }
              * {
                fill: @svg-color;
              }

              &:hover {
                path {
                  fill: @primary-color;
                }
                * {
                  fill: @primary-color;
                }
              }
            }
          }
        }
      }
    }

    .ag-paging-panel {
      @media screen and (max-width: 500px) {
        margin: 0px 0px;

        &.ag-unselectable {
          .ag-paging-row-summary-panel {
            margin: 0px 0px;
            font-size: 10px;

            .ag-paging-button {
              margin: 0px 0px;
            }
          }

          .ag-paging-page-summary-panel {
            font-size: 10px;
            margin: 0px 0px;

            .ag-paging-button {
              margin: 0px 0px;
            }
          }
        }
      }
    }
  }

  .ag-filter {
    .ag-filter-apply-panel {
      button {
        cursor: pointer;
      }
    }
  }

  .globalPopUpForm {
    .ant-form-item-control-input {
      .ant-form-item-control-input-content {
        .ant-picker {
          width: 100%;

          .ant-picker-input {
            input {
              background-color: transparent;
              color: #000;
            }
          }
        }
      }
    }

    .ant-col-4 {
      display: block;
      flex: 0 0 11.666667%;
      max-width: 11.666667%;

      @media screen and(max-width:1670px) {
        flex: 0 0 15%;
        max-width: 15%;
      }

      @media screen and(max-width:1280px) {
        flex: 0 0 25%;
        max-width: 25%;
      }

      @media screen and(max-width:680px) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    .checkbox-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 15px;
      margin-bottom: 20px;

      .button-container {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        button {
          height: 40px;
        }
      }
    }
  }

  .scheduleForm {
    .ant-select {
      border: 1px solid #e9e9e9;
    }
  }

  .full-width-dragger {
    .ant-form-item-control {
      .ant-form-item-control-input-content {
        background: white;
        border-radius: 3px;
        height: auto;
        padding: 0;
      }
    }

    .ant-upload-list-text-container {
      .ant-upload-list-item {
        .ant-upload-list-item-info {
          background-color: @white;
        }
      }
    }
  }
}

.dark-mode {
  .full-width-dragger {
    // .ant-form-item-control {
    //     .ant-form-item-control-input-content {

    //         // height: 38px;
    //         background-color: @dark-even-row;
    //         color: #fff;
    //         border-color: rgba(189, 185, 181, 0.2) !important;
    //         // padding: 0px 12px;
    //     }
    // }

    .ant-upload-list-text-container {
      // height: 37px;

      // .ant-upload-list-item {
      //     .ant-upload-list-item-info {
      //         background-color: @dark-even-row;
      //     }
      // }

      .ant-upload-text-icon {
        svg {
          path {
            fill: @white;
          }
        }
      }
    }
  }

  // .ant-image-preview-root {
  //     &.Portrait {
  //         .ant-image-preview-wrap {
  //             &:has(img.ant-image-preview-img) {
  //                 img {
  //                     width: auto;
  //                     max-width: 60dvw;
  //                     height: 60%;
  //                     max-height: 60dvh;
  //                 }
  //             }
  //         }
  //     }

  //     &.Landscape {
  //         .ant-image-preview-wrap {
  //             &:has(img.ant-image-preview-img) {
  //                 img {
  //                     width: 60%;
  //                     max-width: 60dvw;
  //                     height: auto;
  //                     max-height: 60dvh;
  //                 }
  //             }
  //         }
  //     }
  // }

  // .ant-image {
  //     .ant-image-mask-info {
  //         font-size: 0px;

  //         .anticon-eye {
  //             font-size: 15px;
  //             margin-top: 17px;
  //         }
  //     }
  // }

  .ant-tooltip {
    .ant-tooltip-arrow {
      .ant-tooltip-arrow-content {
        &:before {
          background: linear-gradient(
            to right bottom,
            rgba(29, 118, 187, 0.7),
            rgba(29, 118, 187, 0.7)
          ) !important;
        }
      }
    }

    .ant-tooltip-inner {
      background-color: rgba(29, 118, 187, 0.7);

      span {
        display: flex;
        align-items: center;
        width: auto;
      }

      .extracontent {
        display: flex;
        align-items: center;
        padding: 0px 5px;

        .sidebarArrowIcon {
          align-items: center;
          display: flex;
          padding: 0px 2px;

          svg {
            path {
              fill: @white;
            }
          }
        }
      }
    }
  }

  .globalPopUpForm {
    .ant-col-4 {
      display: block;
      flex: 0 0 11.666667%;
      max-width: 11.666667%;
    }
  }

  .ant-picker-dropdown {
    .ant-picker-date-panel {
      .ant-picker-content {
        width: 160px !important;
        max-width: 100%;

        tr {
          td {
            // .ant-picker-cell-inner {
            //     background-color: transparent !important;
            // }
            .ant-picker-cell-today {
              color: @white;
            }
            &.ant-picker-cell-disabled {
              &::before {
                background-color: @layout-sidebar-dark-background !important;
              }
              .ant-picker-cell-inner {
                color: rgba(255, 255, 255, 0.6);
              }
            }
            &.ant-picker-cell-today {
              &:before {
                background-color: @layout-sidebar-dark-background;
              }
              &.ant-picker-cell-disabled {
                &::before {
                  background-color: transparent !important;
                }
              }
            }
            &.ant-picker-cell-selected {
              .ant-picker-cell-inner {
                border-color: @layout-sidebar-dark-background;
              }
            }
            &.ant-picker-cell-today {
              .ant-picker-cell-inner {
                &:before {
                  border-color: @layout-sidebar-dark-background;
                }
              }
            }
            &.ant-picker-cell-selected {
              .ant-picker-cell-inner {
                background-color: @layout-sidebar-dark-background;
              }
            }
          }
        }
      }
    }

    // .ant-picker-month-panel,
    // .ant-picker-year-panel {
    //     .ant-picker-content {

    //         tr {
    //             td {
    //                 .ant-picker-cell-inner {
    //                     color: @white;
    //                 }
    //             }
    //         }
    //     }
    // }
  }

  .ag-theme-alpine {
    --ag-foreground-color: rgb(248, 246, 248);
    --ag-background-color: rgb(54, 53, 53);
    --ag-header-foreground-color: rgb(204, 245, 172);
    --ag-header-background-color: rgb(39, 35, 37);
    --ag-odd-row-background-color: rgb(0, 0, 0, 0.03);
    --ag-header-column-resize-handle-color: rgb(122, 48, 128);
  }

  .ant-select-dropdown {
    .ant-select-item-empty {
      .ant-empty-normal {
        .ant-empty-description {
          color: @white;
        }
      }
    }
  }

  .ag-sort-indicator-container {
    .ag-sort-indicator-icon {
      .ag-icon-asc {
        color: @white;
      }
    }
  }

  .ag-filter {
    .ag-wrapper {
      .ag-text-field-input {
        &::-webkit-input-placeholder {
          /* Edge */
          color: #fff;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #fff;
        }

        &::placeholder {
          color: #fff;
        }
      }
    }
  }

  .ant-table-wrapper {
    .ant-spin-nested-loading {
      .ant-spin-container {
        .ant-table {
          background-color: @dark-even-row;

          .ant-table-container {
            .ant-table-content {
              table {
                .ant-table-thead {
                  background-color: transparent;

                  tr {
                    th {
                      color: @white;

                      &::before {
                        background-color: @white;
                      }
                    }
                  }
                }

                .ant-table-tbody {
                  tr {
                    .ant-table-cell {
                      a {
                        color: @white;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .editUser {
    .permisionRow {
      div {
        color: @white;
      }
    }
  }
  .permissionCard {
    .ant-card {
      background-color: #393e42;
      color: @white;
    }
    .ant-checkbox-checked {
      &::after {
        border-color: @white !important;
      }
    }
    .ant-checkbox-disabled {
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          border-color: @white !important;
          &::after {
            border-color: @white;
          }
        }
      }
    }
  }

  .ag-menu {
    background-color: @dark-odd-row;
    color: #fff;
  }

  .ag-overlay-wrapper {
    background-color: transparent;
  }

  .ant-checkbox-wrapper {
    // span {
    //     color: @white;
    //     border-color: @dark-odd-row;
    //     background-color: @dark-odd-row;
    // }
    .ant-checkbox-inner {
      border-color: @dark-odd-row !important;
    }
    .ant-checkbox-checked {
      &:after {
        border-color: @dark-odd-row !important;
      }
    }
  }

  .ant-radio-wrapper {
    span {
      color: @white !important;
    }
  }

  .ant-upload-list-item-card-actions {
    button {
      span {
        svg {
          path {
            fill: @white;
          }
        }
      }
    }
  }

  .ant-notification {
    .ant-notification-notice {
      background-color: #414141;

      .ant-notification-notice-content {
        .ant-notification-notice-message {
          color: @white;
        }

        .ant-notification-notice-description {
          color: @white;
        }
      }

      .ant-notification-notice-close {
        .ant-notification-close-x {
          .anticon {
            svg {
              path {
                fill: @white;
              }
            }
          }
        }
      }
    }
  }

  .ant-select {
    .ant-select-selector {
      .ant-select-selection-overflow {
        .ant-select-selection-overflow-item {
          .ant-select-selection-item {
            background-color: @dark-even-row;
            color: @white;
            border: 1px solid @dark-even-row;

            .ant-select-selection-item-remove {
              .anticon {
                svg {
                  path {
                    fill: @white;
                  }
                }
              }
            }
          }
        }
      }
    }
    .ant-select-clear {
      color: @white;
      background-color: @dark-even-row;
    }
  }

  .ant-upload {
    button {
      background-color: @dark-odd-row;
      border: 1px solid @dark-even-row;
      color: @white;

      .anticon {
        svg {
          path {
            fill: @white;
          }
        }
      }

      // span {
      //     color: @primary-color;
      // }
    }
  }
  .ant-btn-primary {
    background-color: @dark-odd-row;
    border: 1px solid @dark-even-row;

    &:hover {
      background-color: @dark-even-row;
      border-color: @dark-odd-row;
      color: @white;
    }
  }

  .ant-upload-list-item {
    .ant-upload-list-item-info {
      .ant-upload-span {
        .ant-upload-list-item-name {
          cursor: pointer;
          color: @white;
        }
      }
    }
  }

  .ant-picker-dropdown {
    .ant-picker-panel-container {
      .ant-picker-panel {
        .ant-picker-date-panel {
          .ant-picker-header {
            border-bottom: 1px solid @dark-border;
            color: @white;

            button {
              color: @white;
            }
          }

          .ant-picker-body {
            .ant-picker-content {
              thead {
                tr {
                  th {
                    color: @white;
                  }
                }
              }

              // tbody {
              //     tr {
              //         td {
              //             color: @white;

              //             &:hover {
              //                 color: #000;
              //             }
              //         }
              //     }
              // }
            }
          }
        }
      }
      .ant-btn-primary {
        background-color: @layout-sidebar-dark-background;
        border-color: @layout-sidebar-dark-background;
        span {
          color: @white;
        }
        &:hover {
          background-color: @dark-odd-row;
          border-color: @dark-odd-row;
        }
      }
    }
  }

  .ant-input-affix-wrapper,
  .ant-input-affix-wrapper-status-error {
    background-color: @dark-odd-row;
    padding: 0px 15px 0px 12px;
    border: 1px solid @dark-border;

    .ant-input-suffix {
      .anticon {
        svg {
          path {
            fill: @white;
          }
        }
      }
    }
  }

  .ant-input-affix-wrapper-status-error:not(
      .ant-input-affix-wrapper-disabled
    ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-input-affix-wrapper-status-error:not(
      .ant-input-affix-wrapper-disabled
    ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background-color: @dark-odd-row;
    border: 1px solid @dark-border;
  }

  .ant-picker-dropdown {
    .ant-picker-panel-container {
      background-color: @dark-odd-row;

      button {
        color: @white;
      }
      .ant-picker-cell {
        &:hover {
          .ant-picker-cell-inner {
            background-color: @layout-sidebar-dark-background !important;
          }
        }
      }
      .ant-picker-cell-inner {
        color: rgba(255, 255, 255, 0.7);
      }
      .ant-picker-cell-in-view {
        .ant-picker-cell-inner {
          color: @white;
        }
      }
      .ant-picker-header-view {
        color: @white;
      }

      .ant-picker-time-panel-column {
        border-left: 1px solid @dark-border;

        .ant-picker-time-panel-cell {
          .ant-picker-time-panel-cell-inner {
            color: @white;

            &:hover {
              background-color: @dark-even-row;
              color: @white;
            }
          }
        }

        .ant-picker-time-panel-cell-selected {
          .ant-picker-time-panel-cell-inner {
            color: @dark-odd-row;
          }
        }
      }
    }

    .ant-picker-footer {
      .ant-picker-now {
        .ant-picker-now-btn {
          color: @white;
          &:hover {
            color: @black;
          }
        }
      }
    }
  }

  .ant-select-dropdown {
    background-color: @dark-odd-row;
    color: @white;

    .ant-select-item-option-content {
      color: @white;
    }

    .ant-select-item-option-active {
      background-color: #2a2a2a;

      // .ant-select-item-option-content {
      //     color: @primary-color;
      // }
    }
    .anticon {
      color: @white;
    }
    // .ant-select-item-option-selected {
    //     .ant-select-item-option-content {
    //         color: @primary-color;
    //     }
    // }
  }

  .userMenuList {
    .ant-dropdown-menu {
      padding: 0px 0;
      width: 100%;
      left: 0px;
      background-color: #444a4f;

      .ant-dropdown-menu-title-content {
        a {
          .regulerFont();
          font-size: 15px;
          color: @white;
          display: flex;
          align-items: center;

          span {
            padding-left: 10px;
          }

          svg {
            g {
              fill: @primary-color;
            }
          }
        }

        div {
          .regulerFont();
          font-size: 15px;
          color: @white;
          display: flex;
          align-items: center;

          span {
            padding-left: 10px;
          }

          svg {
            g {
              path {
                fill: @primary-color;
              }
            }
          }
        }
      }

      .ant-dropdown-menu-item {
        padding: 10px 12px;
        position: relative;
        .transition();

        &:hover {
          background-color: @dark-blue-color;

          a {
            color: @white;

            svg {
              g {
                fill: @white;
              }
            }
          }

          div {
            color: @white;

            svg {
              g {
                path {
                  fill: @white;
                }
              }
            }
          }
        }

        &:nth-child(1) {
          &:after {
            position: absolute;
            background-color: @dark-border;
            width: 100%;
            height: 1px;
            content: "";
            bottom: 0;
            left: 0;
          }
        }

        &:nth-child(2) {
          &:after {
            position: absolute;
            background-color: @dark-border;
            width: 100%;
            height: 1px;
            content: "";
            bottom: 0;
            left: 0;
          }
        }
      }
    }
  }

  //  dark drawer

  .commanDrawer {
    .ant-drawer-content-wrapper {
      @media screen and (max-width: 768px) {
        width: 100% !important;
      }
    }

    .ant-drawer-header {
      padding: 12px 20px;
      background-color: @layout-sidebar-dark-background;
      border-top: 3px solid @primary-color;
      border-bottom: 1px solid @dark-border;

      .ant-drawer-close {
        background-color: #91908e;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        position: absolute;
        right: 10px;

        .anticon-close {
          svg {
            position: absolute;
            top: 6px;
            right: 6px;
            height: 12px;
            width: 12px;

            path {
              fill: #fff;
            }
          }
        }

        svg {
          position: absolute;
          top: 6px;
          right: 6px;
          height: 12px;
          width: 12px;

          path {
            fill: #fff;
          }
        }
      }

      .ant-drawer-title {
        .regulerFont();
        font-size: 20px;
        letter-spacing: 0.5px;
        color: @white;
      }
    }

    .ant-drawer-body {
      background-color: @layout-sidebar-dark-background;

      table {
        .ant-table-tbody {
          .ant-table-row {
            &:hover {
              background: @dark-even-row;
              color: white;
            }
          }

          .ant-table-cell-row-hover {
            background: @dark-even-row;
            color: white;
          }

          .ant-table-row-selected {
            td {
              background-color: rgba(33, 84, 122, 0.34);
              color: white;
            }
          }
        }
      }

      form {
        .ant-form-item-label {
          label {
            .regulerFont();
            letter-spacing: 0.5px;
            font-size: 14px;
            color: @white;
          }
        }

        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            input {
              background-color: @dark-odd-row;
              color: @sideBar-font-color;
              border-radius: 3px;
              height: 38px;
              border: none;
              padding: 0 12px;
              border: 1px solid rgba(189, 185, 181, 0.2);

              &:focus {
                box-shadow: 0px 0px 0px 1px @primary-color;
              }
            }

            .ant-radio-group {
              display: flex;
              width: 64%;
              justify-content: space-between;

              .ant-radio-wrapper {
                span {
                  .regulerFont();
                  letter-spacing: 0.5px;
                  font-size: 16px;
                  color: @modal-font-color;
                  padding-left: 10px;
                }

                .ant-radio {
                  padding-left: 0px;

                  .ant-radio-inner {
                    width: 20px;
                    height: 20px;
                    border-color: #d9d9d9;

                    &:after {
                      width: 12px;
                      height: 12px;
                      margin-top: -6px;
                      margin-left: -6px;
                    }
                  }
                }
              }
            }

            .ant-checkbox-group {
              .ant-checkbox-wrapper {
                span {
                  .regulerFont();
                  letter-spacing: 0.5px;
                  font-size: 16px;
                  color: @white;
                }
              }
            }
          }
        }

        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            textarea {
              background-color: @dark-odd-row;
              color: @white;
              border: 1px solid rgba(189, 185, 181, 0.2);

              &:focus {
                border: 1px solid @primary-color;
              }
            }
          }
        }

        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-checkbox-wrapper {
              span {
                color: @white;
              }

              .ant-checkbox {
                .ant-checkbox-inner {
                  border: 1px solid @primary-color;
                  background-color: @dark-even-row;
                }
              }
            }

            .ant-checkbox-wrapper-checked {
              .ant-checkbox {
                .ant-checkbox-inner {
                  background-color: @dark-odd-row;
                }
              }
            }

            .ant-checkbox-wrapper-disabled {
              .ant-checkbox-disabled {
                .ant-checkbox-inner {
                  border: 1px solid @primary-color !important;
                  background-color: @label-color;
                  opacity: 0.5;
                }
              }
            }
          }
        }

        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-radio-button-wrapper {
              background-color: @dark-even-row;
              border-color: rgba(189, 185, 181, 0.2);

              span {
                color: @white;
              }
            }

            // .ant-radio-button-wrapper-checked {
            //     background-color: @primary-color;
            // }
          }
        }

        .ant-form-item-control-input-content {
          .tox {
            border: 1px solid rgba(189, 185, 181, 0.2);

            .tox-edit-area__iframe {
              background-color: #444a4f;

              #tinymce {
                p {
                  color: @white;
                }
              }
            }
          }

          .tox-editor-container {
            svg {
              path {
                fill: @white;
              }
            }

            .tox-tbtn__select-chevron {
              svg {
                path {
                  fill: #000 !important;
                }
              }
            }
          }

          .tox-editor-header {
            background-color: @dark-odd-row;

            .tox-toolbar__primary {
              background-color: @dark-odd-row;
            }
          }

          .tox-statusbar {
            background-color: @dark-even-row;
            border-top: 1px solid @dark-border;

            span {
              color: @white;
            }

            div {
              color: @white;
            }

            button {
              color: @white;
            }
          }
        }

        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-form-item-extra {
              color: @white;
            }
          }
        }

        .ant-input-status-error {
          background-color: @dark-odd-row !important;
        }

        .ant-select {
          background-color: @dark-odd-row;
          border: 1px solid rgba(189, 185, 181, 0.2);
          border-radius: 3px;

          .ant-select-selector {
            border: none;
            background-color: @dark-odd-row;
            height: 38px;
            border-radius: 3px;
            padding: 0 12px;
            color: @white;
            color: @sideBar-font-color;

            .ant-select-selection-overflow {
              .ant-select-selection-overflow-item {
                input {
                  &:focus {
                    box-shadow: none;
                  }
                }
              }
            }

            .ant-select-selection-search {
              input {
                background-color: transparent;
                padding: 0px 0px;

                &:focus {
                  box-shadow: none;
                }
              }
            }

            .ant-form-item-has-error {
              .ant-select-selector {
                background-color: @dark-odd-row;
              }
            }
          }
        }

        .ant-select-arrow {
          svg {
            path {
              fill: @white;
            }
          }
        }

        .ant-form-item-has-error {
          .ant-select-selector {
            background-color: @dark-odd-row !important;
          }
        }

        // .ant-select-focused {
        //     box-shadow: 0px 0px 0px 1px @primary-color;
        //     border-radius: 3px;
        // }

        .ant-picker {
          border: 1px solid rgba(189, 185, 181, 0.2);
          background-color: @dark-odd-row;
          color: @sideBar-font-color;
          height: 38px;
          border-radius: 3px;
          width: 100%;
          padding: 0 12px;

          .ant-picker-input {
            input {
              color: @white !important;
              background-color: transparent !important;
              border: none !important;

              &:focus {
                box-shadow: none !important;
              }
            }
          }

          &:hover {
            background-color: @dark-odd-row;
          }

          .ant-picker-suffix {
            color: @white;
          }

          .ant-picker-clear {
            right: -10px;
          }
        }

        .ant-picker-status-error {
          background-color: @dark-odd-row;
        }

        // .ant-picker-focused {
        //     box-shadow: 0px 0px 0px 1px @primary-color;
        //     border-radius: 3px;

        // }

        .ant-form-item-explain-error {
          font-size: 13px;
          color: @error-color;
          padding: 5px 12px;
          letter-spacing: 0.5px;
          .boldFont();
        }
      }

      .selectDown {
        .ant-form-item-extra {
          position: absolute;
          right: 7px;
          bottom: 4px;
        }
      }

      #addeditform,
      #roleForm {
        button {
          &.modifyBtn {
            background: transparent;
            padding: 4px 10px;
            border: 1px solid @white;
            height: 38px;
            border-radius: 3px;
            .transition();

            span {
              .regulerFont();
              color: @white;
              .transition();
            }

            &:hover {
              border: 1px solid @primary-color;

              span {
                color: @primary-color;
              }
            }
          }

          &.disibleModifyBtn {
            background: #d9d9d9;
            padding: 4px 10px;
            border: 1px solid @white;
            height: 38px;
            border-radius: 3px;

            span {
              color: #7c7c7c;
            }
          }
        }

        .permisionRow {
          div {
            color: @white;
          }
        }

        .permissions_form_item {
          .permissionCard {
            .ant-card-bordered {
              border: 1px solid @dark-border;
            }

            .ant-card-body {
              background-color: @dark-even-row;

              .ant-row {
                .ant-col {
                  border-right: 1px solid @dark-border !important;

                  b {
                    color: @white;
                  }

                  .ant-checkbox-wrapper {
                    span {
                      color: @white;
                    }
                  }

                  .ant-checkbox-wrapper-disabled {
                    .ant-checkbox-disabled {
                      span {
                        color: @label-color !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .ant-picker-dropdown {
        .ant-picker-panel-container {
          background-color: @dark-odd-row;
        }
      }

      .formatRadioBtn {
        .ant-radio-group {
          width: 100% !important;
        }
      }

      .ant-form-item {
        .layoutOptions {
          .ant-upload-list {
            .ant-upload-list-item {
              background-color: @dark-odd-row !important;
              // border-radius: 3px;
              // height: 50px;
              // border: 1px solid rgba(189, 185, 181, 0.2) !important;
              // padding: 0 12px;

              &:hover {
                .ant-upload-list-item-info {
                  background-color: @dark-odd-row !important;
                }
              }

              .ant-upload-list-item-info {
                .ant-upload-text-icon {
                  span {
                    svg {
                      path {
                        fill: @white;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .ant-drawer-footer {
      // padding: 12px 20px;
      background-color: @layout-sidebar-dark-background;
      border-color: @dark-border;
      // display: flex;
      // align-items: center;
      // justify-content: flex-start;

      .blackLineBtn {
        background-color: @dark-odd-row;
        border-color: @dark-odd-row;

        span {
          color: @white;
        }
        &:hover {
          border-color: @dark-odd-row;
          background-color: transparent;
          span {
            color: @white;
          }
        }
      }
      .orangeBgBtn {
        border-color: @dark-odd-row;

        span {
          color: @white;
        }
        &:hover {
          background-color: @dark-odd-row;
          border-color: @dark-odd-row;

          span {
            color: @white;
          }
        }
      }

      // button {
      //     height: 40px;
      //     padding: 6px 30px;
      //     border-radius: 3px;
      //     margin: 0px 12px 0px 0px;
      // }

      // .blackLineBtn {
      //     background-color: @primary-color;
      //     color: #fff;
      //     height: 40px;
      //     border: 1px solid transparent;
      //     .transition();
      //     padding: 6px 30px;

      //     span {
      //         color: @white;
      //         .regulerFont();
      //         letter-spacing: 0.5px;
      //         // font-size: 15px;
      //         .transition();
      //     }

      //     &:hover {
      //         border: 1px solid @primary-color;
      //         background-color: @white;

      //         span {
      //             color: @primary-color;
      //         }
      //     }

      // }

      .disableBtn {
        padding: 6px 30px;
        height: 40px;

        span {
          color: @label-color;
        }
      }

      // .orangeBgBtn {
      //     padding: 6px 30px;
      //     height: 40px;

      //     background-color: transparent;
      //     border: 1px solid @white;
      //     .transition();

      //     span {
      //         color: @white;
      //         letter-spacing: 0.5px;
      //         .regulerFont();
      //         // font-size: 15px;
      //         .transition();
      //     }

      //     &:hover {
      //         border: 1px solid @primary-color;

      //         span {
      //             color: @primary-color;
      //         }
      //     }

      // }

      .redBgBtn {
        background-color: @error-color;
        color: #fff;
        padding: 6px 30px;
        height: 40px;
        border: 1px solid transparent;
        .transition();

        span {
          color: @white;
          letter-spacing: 0.5px;
          .regulerFont();
          font-size: 15px;
          .transition();
        }

        &:hover {
          border: 1px solid @error-color;
          background-color: @white;

          span {
            color: @error-color;
          }
        }
      }

      .centerBtn {
        display: flex;
        justify-content: center;

        .orangeBgBtn {
          background-color: @primary-color;
          color: #fff;
          padding: 6px 30px;
          height: 42px;

          border: 1px solid transparent;
          .transition();

          span {
            color: @white;
            letter-spacing: 0.5px;
            .regulerFont();
            font-size: 16px;
            .transition();
          }

          &:hover {
            border: 1px solid @primary-color;
            background-color: @white;

            span {
              color: @primary-color;
            }
          }
        }
      }
    }

    .color-picker-input-design {
      margin-bottom: 12px;
      padding: 0px 12px;

      .ant-form-item-label {
        .regulerFont();
        color: @font-color;
        font-size: 15px;
      }

      .ant-form-item-control-input {
        background-color: transparent;
        height: 38px;
        border: none;
        border-radius: 3px;

        .ant-form-item-control-input-content {
          height: 38px;

          div {
            box-shadow: none !important;
            height: 38px !important;
            background-color: #393e42;
            border: none !important;
          }
        }
      }
    }

    #publishLayouform {
      .ant-col-12 {
        @media screen and(max-width:600px) {
          display: block;
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  }

  //  dark Modal

  .commanModal {
    .ant-modal-close {
      position: absolute;
      top: 13px;
      right: 20px;

      .ant-modal-close-x {
        background-color: #91908e;
        width: 24px;
        height: 24px;
        border-radius: 50%;

        svg {
          position: absolute;
          top: 6px;
          right: 6px;
          height: 12px;
          width: 12px;

          path {
            fill: #fff;
          }
        }
      }
    }

    .ant-picker {
      .ant-picker-clear {
        right: -8px;
      }
    }

    .ant-modal-header {
      border-top: 3px solid @primary-color;
      padding: 12px 20px;
      background-color: @layout-sidebar-dark-background;
      border-bottom: 1px solid @dark-border;

      .ant-modal-title {
        .regulerFont();
        font-size: 20px;
        color: @white;
        letter-spacing: 0.5px;
      }
    }

    .ant-modal-body {
      padding: 25px 20px 25px 20px;
      background-color: @layout-sidebar-dark-background;
      h4 {
        color: @white;
      }

      form {
        .ant-form-item {
          margin-bottom: 12px;

          .ant-form-item-label {
            label {
              letter-spacing: 0.5px;
              .regulerFont();
              font-size: 14px;
              color: @white;
            }
          }

          .ant-form-item-control-input {
            .ant-form-item-control-input-content {
              input {
                background-color: @dark-odd-row;
                border-radius: 3px;
                height: 38px;
                border: none;
                padding: 0 12px;
                color: @white;
                border: 1px solid rgba(189, 185, 181, 0.2);

                &:focus {
                  box-shadow: 0px 0px 0px 1px @primary-color;
                }
              }

              .ant-radio-group {
                display: flex;
                width: 68%;
                justify-content: space-between;

                .ant-radio-wrapper {
                  span {
                    .regulerFont();
                    font-size: 16px;
                    color: @white;
                    padding-left: 10px;
                  }

                  .ant-radio {
                    padding-left: 0px;

                    .ant-radio-inner {
                      width: 20px;
                      height: 20px;
                      border-color: @dark-border;

                      &:after {
                        width: 12px;
                        height: 12px;
                        margin-top: -6px;
                        margin-left: -6px;
                      }
                    }
                  }
                }
              }

              .ant-checkbox-group {
                .ant-checkbox-wrapper {
                  span {
                    letter-spacing: 0.5px;
                    .regulerFont();
                    font-size: 16px;
                    color: @white;
                  }
                }
              }
            }
          }

          // .ant-form-item-control-input {
          //     .ant-form-item-control-input-content {
          //         textarea {
          //             background-color: @dark-odd-row;
          //             color: @white;
          //             border: 1px solid rgba(189, 185, 181, 0.2) !important;

          //             &:focus {
          //                 border: 1px solid @primary-color  !important;
          //             }
          //         }
          //     }

          // }
        }

        // border: 1px solid rgba(189, 185, 181, 0.2) !important;

        .ant-select {
          border: 1px solid rgba(189, 185, 181, 0.2) !important;

          .ant-select-selector {
            border: none;
            background-color: @dark-odd-row;
            height: 38px;
            border-radius: 3px;
            padding: 0 12px;
            color: @white;

            .ant-select-selection-overflow {
              .ant-select-selection-overflow-item {
                input {
                  &:focus {
                    box-shadow: none;
                  }
                }
              }
            }

            .ant-select-selection-item {
              line-height: 38px !important;
            }

            .ant-form-item-has-error {
              background-color: @dark-odd-row;
            }
          }
        }

        .ant-select-arrow {
          svg {
            path {
              fill: @white;
            }
          }
        }

        .ant-form-item-has-error {
          .ant-select-selector {
            background-color: @dark-odd-row !important;
          }
        }

        .ant-select-focused {
          box-shadow: 0px 0px 0px 1px @dark-odd-row;
        }

        .ant-picker {
          border: 1px solid rgba(189, 185, 181, 0.2);
          background-color: @dark-odd-row;
          height: 38px;
          border-radius: 3px;
          width: 100%;
          padding: 0 12px 0px 0px;

          .ant-picker-input {
            input {
              padding: 0 12px 0px 0px;
              color: @font-color;
              background-color: transparent !important;

              &:focus {
                box-shadow: none !important;
              }
            }
          }

          .ant-picker-suffix {
            color: white;
          }
        }

        .ant-picker-focused {
          box-shadow: 0px 0px 0px 1px @primary-color;
          border-radius: 3px;
        }

        .ant-form-item-explain-error {
          font-size: 13px;
          color: @error-color;
          padding: 5px 12px;
          letter-spacing: 0.5px;
          .boldFont();
        }

        h3 {
          .regulerFont();
          color: @white;
          font-size: 16px;
        }
      }

      #publishform {
        @media screen and(max-width:768px) {
          .ant-image {
            width: 100%;
          }
        }

        .ant-form-item-control {
          .ant-form-item-control-input {
            .ant-form-item-control-input-content {
              .ant-select {
                .ant-select-selector {
                  height: 38px;

                  .ant-select-selection-search {
                    input {
                      height: 38px;
                      background-color: transparent !important;
                    }
                  }
                }
              }
            }
          }
        }

        .ant-col {
          h3 {
            letter-spacing: 1px;
            padding: 0px 10px;
          }
        }

        .warningImg {
          text-align: center;

          svg {
            height: 70px;
            width: 70px;

            g {
              path {
                stroke-width: 1;
              }
            }
          }
        }
      }

      .selectDown {
        .ant-form-item-extra {
          position: absolute;
          right: 7px;
          // bottom: 4px;
        }
      }

      .deviceDetails {
        p {
          color: grey;

          span {
            color: @white;
          }
        }
      }
    }

    .ant-modal-footer {
      padding: 12px 20px;
      background-color: @layout-sidebar-dark-background;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid @dark-border;

      // button {
      //     height: 40px;
      //     padding: 6px 30px;
      //     border-radius: 3px;
      //     margin: 0px 12px 0px 0px;
      // }

      // .blackLineBtn {
      //     background-color: @primary-color;
      //     color: #fff;
      //     border: 1px solid transparent;
      //     .transition();
      //     height: 40px;
      //     padding: 6px 30px;

      //     span {
      //         color: @white;
      //         letter-spacing: 0.5px;
      //         .regulerFont();
      //         // font-size: 15px;
      //         .transition();
      //     }

      //     &:hover {
      //         border: 1px solid @primary-color;

      //         span {
      //             color: @primary-color;
      //         }
      //     }

      // }

      .orangeBgBtn {
        background-color: transparent;
        border: 1px solid @white;
        .transition();
        height: 40px;
        padding: 6px 30px;

        span {
          color: @white;
          letter-spacing: 0.5px;
          .regulerFont();
          // font-size: 15px;
          .transition();
        }

        &:hover {
          border: 1px solid @primary-color;
          background-color: transparent;

          span {
            color: @primary-color;
          }
        }
      }

      .disableBtn {
        border-color: transparent;
        padding: 6px 30px;
        height: 40px;

        span {
          color: @label-color;
        }
      }

      .redBgBtn {
        background-color: @error-color;
        color: #fff;
        height: 40px;
        border: 1px solid transparent;
        padding: 6px 30px;
        .transition();

        span {
          color: @white;
          letter-spacing: 0.5px;
          .regulerFont();
          // font-size: 15px;
          .transition();
        }

        &:hover {
          border: 1px solid @error-color;
          background-color: @white;

          span {
            color: @error-color;
          }
        }
      }
    }
  }

  .scheduleLayout {
    .ant-select {
      border: 1px solid rgba(189, 185, 181, 0.2);
    }
  }

  .scheduleDrawer,
  .publishLayout {
    .ant-table {
      background-color: @dark-even-row;

      .ant-table-container {
        .ant-table-content {
          .ant-table-thead {
            background-color: @dark-odd-row;
            border-bottom: 1px solid @dark-border;

            .ant-table-cell {
              color: @white;
            }
          }

          .ant-table-tbody > tr > td {
            border-bottom-color: @dark-border;
            border-right-color: @dark-border;
          }

          .ant-table-tbody {
            .ant-table-cell {
              a {
                color: @white;
              }
            }
          }
        }
      }

      table {
        border: 1px solid @dark-border;
      }
    }
  }

  .editorLayout {
    .tox-tinymce {
      .tox-editor-container {
        .tox-sidebar-wrap {
          .tox-edit-area {
            .tox-edit-area__iframe {
              html {
                .mce-content-body {
                  color: white;

                  p {
                    color: @white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // .ant-picker-dropdown-placement-bottomLeft{
  // 	.ant-picker-year-panel{
  // 		.ant-picker-body{
  // 			.ant-picker-body{
  // 				.ant-picker-content{
  // 					tr{
  // 						td{
  // 							.ant-picker-cell-inner{
  // 								color:@white;
  // 							}
  // 						}
  // 					}
  // 				}
  // 			}
  // 		}
  // 	}
  // }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    &:before {
      background-color: rgba(189, 185, 181, 0.2);
    }
  }
}

.dark__layout {
  .main__app__content {
    // padding: 20px 20px 0px;
    padding: 10px 35px;
    background-color: @dark-layout-bg;
    .transition();
    height: calc(100vh - 140px);
    overflow-y: auto;

    .ant-page-header-heading-extra {
      display: flex;
      flex-wrap: wrap;

      .ant-space-horizontal {
        flex-wrap: wrap;

        @media screen and (max-width: 480px) {
          .ant-space-item {
            button {
              padding: 6px 22px;

              span {
                font-size: 12px;
              }
            }
          }
        }

        @media screen and (max-width: 467px) {
          .ant-space-item {
            button {
              padding: 6px 9px;
            }
          }
        }

        @media screen and (max-width: 400px) {
          .ant-space-item {
            button {
              padding: 6px 10px;
            }
          }
        }
      }
    }

    .dashBoardWrap {
      h1 {
        font-size: 22px;
        .regulerFont();
        color: @white;
      }
    }

    .ant-breadcrumb-separator {
      svg {
        transform: rotate(180deg);

        path {
          fill: #c2c4c6;
        }
      }
    }

    .ant-page-header-heading-left {
      display: flex;
      align-items: center;
      margin: 4px 0;

      .ant-page-header-heading-title {
        order: 2;
        display: inline-flex;
      }
    }

    @media screen and (max-width: 400px) {
      padding: 10px 15px;
    }

    .ant-page-header {
      .ant-page-header-heading-left {
        .ant-page-header-heading-title {
          font-size: 22px;
          .regulerFont();
          color: @white;

          @media screen and (max-width: 350px) {
            font-size: 20px;
          }
        }
      }

      .ant-page-header-heading-extra {
        .pageSelectBtn {
          .ant-select-selector {
            height: 42px;
            border-radius: 3px;
            background-color: @layout-sidebar-dark-background;
            border: 1px solid @dark-border;
            .transition();

            .ant-select-selection-item {
              color: @primary-color;
              line-height: 40px;
              font-size: 14px;

              font-family: @primary-font;
              border: none;
              .transition();

              @media screen and (max-width: 480px) {
                line-height: 35px;
                font-size: 12px;
              }

              @media screen and (max-width: 400px) {
                line-height: 32px;
                font-size: 12px;
              }
            }

            &:hover {
              .ant-select-selection-item {
                color: @white;

                @media screen and (max-width: 400px) {
                  padding-right: 15px;
                }
              }

              border: 1px solid @white;
              border-radius: 3px;
            }

            @media screen and (max-width: 480px) {
              height: 35px;
              padding: 0px 30px;
            }

            @media screen and (max-width: 467px) {
              height: 35px;
              padding: 0px 10px;
            }
          }

          .ant-select-arrow {
            .ant-select-suffix {
              svg {
                fill: @primary-color;
                .transition();
              }
            }
          }

          &:hover {
            .ant-select-arrow {
              .ant-select-suffix {
                svg {
                  fill: @white;
                }
              }
            }
          }
        }
      }
    }

    .ant-btn-primary {
      background: @layout-sidebar-dark-background;
      // text-shadow: none;
      // box-shadow: none;
      // font-size: 14px;
      // height: auto;
      // letter-spacing: 0.5px;
      // padding: 8px 25px;
      border-color: @dark-border;
      // border: 1px solid @dark-border;
      // border-radius: 3px;
      .transition();

      // span {
      //     font-size: 14px;
      //     color: @primary-color;
      //     font-family: @primary-font;

      // }

      &:hover {
        border: 1px solid @white;
        color: @white;

        // transform: scale(0.95);
        span {
          color: @white;
        }
      }

      &.mr-10 {
        @media screen and (max-width: 350px) {
          margin-right: 0px;
        }
      }
    }

    .globalPopUpForm,
    .popUpSetting {
      .ant-form-item-label {
        label {
          color: @white;
        }
      }

      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          border: @dark-border;
          background-color: @dark-even-row;
          border-radius: 3px;

          .ant-picker {
            background-color: @dark-even-row;
            width: 100%;
            border: @dark-border;

            .ant-picker-input {
              background-color: @dark-even-row;
              border: @dark-border;

              input {
                background-color: transparent;
                color: @white;
                border: @dark-border;
              }
            }

            .ant-picker-suffix {
              color: @white;
            }

            .ant-picker-clear {
              right: -10px;
            }

            &.ant-picker-focused {
              border: 1px solid @primary-color !important;
              box-shadow: none;
            }
          }

          // border: 1px solid rgba(189, 185, 181, 0.2);
        }
      }

      .ant-form-item-control-input-content {
        .tox {
          border: 1px solid rgba(189, 185, 181, 0.2);
          border-radius: 3px;

          .tox-edit-area__iframe {
            background-color: #444a4f;

            html {
              color: @white;

              #tinymce {
                p {
                  color: @white;
                }
              }
            }
          }
        }

        .tox-editor-container {
          svg {
            path {
              fill: @white;
            }
          }

          .tox-tbtn__select-chevron {
            svg {
              path {
                fill: #000 !important;
              }
            }
          }
        }

        .tox-editor-header {
          background-color: @dark-odd-row;

          .tox-toolbar__primary {
            background-color: @dark-odd-row;
          }
        }

        .tox-statusbar {
          background-color: @dark-even-row;
          border-top: 1px solid @dark-border;

          span {
            color: @white;
          }

          div {
            color: @white;
          }

          button {
            color: @white;
          }
        }
      }

      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          .ant-input {
            border: 1px solid rgba(189, 185, 181, 0.2) !important;
          }
        }
      }
    }

    .globalPopUpForm {
      .ant-picker {
        border: 1px solid rgba(189, 185, 181, 0.2) !important;

        &.ant-picker-focused {
          border: 1px solid @primary-color !important;
          box-shadow: none;
        }
      }
    }

    .scheduleForm {
      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          .ant-select {
            .ant-select-selector {
              border: @dark-border;
              background-color: @dark-even-row;
              padding: 0px 12px;
              color: @white;

              input {
                color: @white;
                border: @dark-border;
              }
            }

            .ant-select-arrow {
              svg {
                path {
                  fill: @white;
                }
              }
            }
          }
        }
      }
    }

    .scheduleCalender {
      .ant-picker-calendar-header {
        .ant-picker-calendar-year-select {
          .ant-select-selector {
            background-color: #444a4f;

            .ant-select-selection-item {
              color: @white;
            }
          }

          .ant-select-arrow {
            svg {
              path {
                fill: @white;
              }
            }
          }
        }

        .ant-picker-calendar-month-select {
          .ant-select-selector {
            background-color: #444a4f;

            .ant-select-selection-item {
              color: @white;
            }
          }

          .ant-select-arrow {
            svg {
              path {
                fill: @white;
              }
            }
          }
        }
      }

      .ant-picker-calendar {
        background-color: @dark-odd-row;

        .ant-picker-body {
          background-color: @dark-even-row;

          .ant-picker-calendar-date-value {
            color: @white;
          }

          .ant-picker-calendar-date {
            border-top: 1px solid @dark-border;
          }
        }

        .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
            .ant-picker-cell-range-start
          ):not(.ant-picker-cell-range-end):not(
            .ant-picker-cell-range-hover-start
          ):not(.ant-picker-cell-range-hover-end)
          .ant-picker-cell-inner {
          background-color: @dark-odd-row;
        }

        .ant-picker-cell-inner {
          .ant-picker-calendar-date-content {
            padding: 0px 10px;

            .events {
              .ant-badge-status {
                .ant-badge-status-text {
                  color: @white;
                }
              }
            }
          }
        }

        .ant-picker-cell-selected {
          .ant-picker-cell-inner {
            .ant-picker-calendar-date-content {
              padding: 0px 10px;

              .events {
                .ant-badge-status {
                  .ant-badge-status-text {
                    color: #000;
                  }
                }
              }
            }
          }
        }

        .ant-picker-calendar-date-today {
          .ant-picker-calendar-date-value {
            color: @primary-color;
          }
        }

        .ant-picker-cell-selected {
          .ant-picker-calendar-date-value {
            color: #000;
          }
        }
      }

      .ant-radio-group {
        .ant-radio-button-wrapper {
          background-color: @dark-even-row;
          color: @white;
        }

        .ant-radio-button-wrapper-checked {
          span {
            color: @primary-color;
          }
        }
      }
    }

    .popUpSetting {
      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            border-radius: 3px;

            input {
              background-color: @dark-even-row;
              color: @white;
              border: @dark-border;
              padding: 0px 12px;

              &:focus {
                border: 1px solid @primary-color !important;
              }
            }
          }
        }
      }
    }

    .tokenLayout {
      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            input {
              background-color: @dark-even-row;
              color: @white;
              border: 1px solid rgba(189, 185, 181, 0.2) !important;

              padding: 0px 12px;

              &:focus {
                border: 1px solid @primary-color !important;
              }
            }
          }
        }
      }

      .ant-col-6 {
        @media screen and(max-width:800px) {
          flex: 0 0 50%;
          max-width: 50%;
        }

        @media screen and(max-width:400px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }

    .unauthorized__section {
      .ant-result-title,
      .ant-result-subtitle {
        color: @white;
      }
    }
  }

  footer {
    &.ant-layout-footer {
      background-color: @dark-header-navigation;
      .transition();
      padding: 15px 35px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      div {
        .regulerFont();
        font-size: 14px;
        color: @white;
        // letter-spacing: 0.5px;

        @media screen and (max-width: 768px) {
          font-size: 12px;
        }

        @media screen and (max-width: 350px) {
          font-size: 8px;
        }
      }

      @media screen and (max-width: 600px) {
        // justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .footerLink {
        color: @dark-blue-color;
        .transition();

        &:hover {
          color: #5694c4;
        }
      }

      .otherLink {
        a {
          color: @white;

          &:hover {
            color: #5694c4;
          }
        }
      }
    }
  }

  .ant-page-header-content {
    .ag-grid-wrapper.ag-theme-alpine {
      // height: calc(100vh - 208px) !important;

      .ag-root-wrapper {
        // height: calc(100vh - 262px);
        border: none;
        background-color: @layout-sidebar-dark-background;
        border-radius: 3px;
        .transition();

        //    .ag-root {		background-color: @layout-sidebar-dark-background;}
        .ag-header {
          border-bottom-color: transparent;
          background-color: @layout-sidebar-dark-background;

          .ag-pinned-left-header {
            border: none;
            background-color: @white;
          }

          .ag-header-cell {
            border-top: none;
            // background-color: @layout-sidebar-dark-background;
            height: 30px;

            .ag-floating-filter-button-button {
              color: @white;
              cursor: pointer;
              .transition();

              &:hover {
                color: @primary-color;
              }
            }

            .ag-cell-label-container {
              .ag-header-icon {
                .ag-icon-menu {
                  color: @primary-color;
                }
              }
            }
          }

          .ag-input-wrapper {
            .ag-input-field-input {
              background-color: @dark-odd-row;
              transition: none;
              border: none;
              height: 30px;
              border-radius: 3px;
              color: @white;
              .transition();
            }
          }

          .ag-header-cell-label {
            .ag-header-cell-text {
              .boldFont();
              font-size: 15px;
              color: @white;

              @media screen and (max-width: 400px) {
                font-size: 13px;
              }
            }
          }

          .ag-header-row {
            &:after {
              background-color: transparent;
              content: none;
            }
          }

          // .ag-header-container{
          // 	background-color: @layout-sidebar-dark-background;

          // }
        }

        .ag-root-wrapper-body {
          padding: 10px 25px;

          .ag-header-cell {
            .ag-header-cell-resize {
              &:after {
                display: none;
              }
            }
          }
        }

        .ag-pinned-right-header {
          border-left: none;
        }

        .ag-body-viewport {
          // padding-top:10px;
          .ag-row {
            border-bottom-color: #23282c;

            .ag-cell {
              border-right: none;
              line-height: 35px;
              border-left: none;
              .regulerFont();
              text-align: left;
              color: @white;

              .ant-image {
                height: 100%;

                // width:100%;
                .ant-image-img {
                  height: 100%;
                }
              }
            }
          }

          .ag-pinned-right-cols-container {
            .ag-row {
              .ag-cell {
                width: 230px;
              }
            }
          }

          .ag-row-even {
            background-color: @dark-even-row;
          }

          .ag-row-odd {
            background-color: @dark-odd-row;
          }

          .statusIcon {
            line-height: 3.5;

            .statusOnIcon {
              height: 20px;
              width: 20px;
              .transition();

              path {
                fill: #f4516c;
              }

              // &:hover{
              // 	path{
              // 		fill:@dark-blue-color
              // 	}
              // }
            }

            .statusOffIcon {
              height: 20px;
              width: 20px;
              .transition();

              path {
                fill: #34bfa3;
              }
            }
          }
        }

        .ag-overlay {
          .ag-overlay-panel {
            .ag-overlay-wrapper {
              &.ag-overlay-no-rows-wrapper {
                color: white;
              }
            }
          }
        }

        .action-column {
          .ant-btn {
            line-height: 3;

            svg {
              height: 16px;
              width: 16px;
              .transition();

              path {
                fill: @white;
              }
              * {
                fill: @white;
              }

              &:hover {
                path {
                  fill: @primary-color;
                }
                * {
                  fill: @primary-color;
                }
              }
            }
          }
        }
      }

      .ag-popup {
        border: none;
        color: @white;

        .ag-menu-header {
          background-color: #444a4f;

          .ag-icon-filter {
            color: @white;
          }
        }

        .ag-menu-body {
          background-color: #444a4f;

          .ag-set-filter-item-checkbox {
            .ag-checkbox-label {
              color: @white;
            }
          }
        }

        .ag-set-filter {
          .ag-filter-no-matches {
            color: @white;
          }
        }

        .ag-tab {
          border-bottom: none;
        }

        .ag-menu {
          border: none;
          box-shadow: 0 1px 4px 1px rgba(119, 118, 117, 0.24);
        }

        .ag-tabs-header {
          color: white;
        }
      }
    }

    .ag-paging-panel {
      color: @white;
      border-top-color: #414141;

      @media screen and (max-width: 500px) {
        margin: 0px 0px;

        &.ag-unselectable {
          .ag-paging-row-summary-panel {
            margin: 0px 0px;
            font-size: 10px;

            .ag-paging-button {
              margin: 0px 0px;
            }
          }

          .ag-paging-page-summary-panel {
            font-size: 10px;
            margin: 0px 0px;

            .ag-paging-button {
              margin: 0px 0px;
            }
          }
        }
      }

      .ag-paging-page-summary-panel {
        .ag-paging-button {
          color: #fff;
        }
      }
    }
  }

  .ag-filter {
    .ag-filter-apply-panel {
      button {
        cursor: pointer;
      }
    }
  }

  .main__app__content {
    .scheduleLayout {
      .listIcon {
        .ant-btn {
          background-color: @dark-odd-row;
          border-color: @dark-odd-row;
          color: @white;

          &.selected {
            border-color: @layout-sidebar-dark-background;
            background-color: @layout-sidebar-dark-background;

            &:hover {
              background-color: @dark-odd-row;
              border-color: @dark-odd-row;
            }
          }

          &:hover {
            border-color: @layout-sidebar-dark-background;
            background-color: @layout-sidebar-dark-background;
          }
        }
        .ant-select {
          position: absolute;
          right: 10px;

          .ant-select-selector {
            background-color: @dark-odd-row;
            border-color: @dark-odd-row;
            color: @white;

            svg {
              fill: @white;
            }
          }
        }
      }
    }
  }
}

::-webkit-input-placeholder {
  /* Edge */
  color: rgba(0, 0, 0, 0.7);
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(0, 0, 0, 0.7);
}

::placeholder {
  color: rgba(0, 0, 0, 0.7);
}

.loginForm .ant-form .ant-input:-webkit-autofill,
.loginForm .ant-form .ant-input:-webkit-autofill:hover,
.loginForm .ant-form .ant-input:-webkit-autofill:focus,
.loginForm .ant-form .ant-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px @primary-color inset !important;
}

.fullscreen__spinner__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  .ant-spin-dot-item {
    background-color: @primary-color;
  }
}

.userMenuList {
  .ant-dropdown-menu {
    padding: 0px 0;
    width: 100%;
    left: 0px;

    .ant-dropdown-menu-title-content {
      a {
        .regulerFont();
        font-size: 15px;
        color: @font-color;
        display: flex;
        align-items: center;

        span {
          padding-left: 10px;
        }

        svg {
          g {
            fill: @primary-color;
          }
        }
      }

      div {
        .regulerFont();
        font-size: 15px;
        color: @font-color;
        display: flex;
        align-items: center;

        span {
          padding-left: 10px;
        }

        svg {
          g {
            path {
              fill: @primary-color;
            }
          }
        }
      }
    }

    .ant-dropdown-menu-item {
      padding: 10px 12px;
      position: relative;
      .transition();

      &:hover {
        background-color: @dark-blue-color;

        a {
          color: @white;

          svg {
            g {
              fill: @white;
            }
          }
        }

        div {
          color: @white;

          svg {
            g {
              path {
                fill: @white;
              }
            }
          }
        }
      }

      &:nth-child(1) {
        &:after {
          position: absolute;
          background-color: @input-color;
          width: 100%;
          height: 1px;
          content: "";
          bottom: 0;
          left: 0;
        }
      }

      &:nth-child(2) {
        &:after {
          position: absolute;
          background-color: @input-color;
          width: 100%;
          height: 1px;
          content: "";
          bottom: 0;
          left: 0;
        }
      }
    }
  }
}

.headerBtn {
  .addBtn {
    padding: 12px 14px;
    height: auto;
    line-height: 16px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    border-color: #d0d0d0;
    margin-left: 15px;
    background: transparent;
    box-shadow: none;
    text-shadow: none;
    transition: all 0.5s ease;

    &:hover {
      color: @white;
      border-color: @primary-color;
      background: @primary-color;
    }
  }
}

.ag-root-wrapper-body.ag-layout-normal {
  flex: 1 1 auto;
  height: 100% !important;
  min-height: 0;
}

.deleteNote {
  font-size: 16px;
  color: #939393;
  font-weight: 500;
  text-align: center;

  span {
    color: #444;
  }
}

::-moz-selection {
  background-color: @primary-color;
}

::selection {
  background-color: @primary-color;
}

// page header

.ant-breadcrumb {
  .ant-breadcrumb-link {
    a {
      color: @font-color;
      transition: none;
      font-size: 14px;
    }
  }
}

//  comman header button

.ant-btn-primary {
  background: @primary-color;
  text-shadow: none;
  box-shadow: none;
  font-size: 14px;

  height: auto;
  letter-spacing: 0.5px;
  padding: 8px 25px;
  border-radius: 3px;
  .transition();

  span {
    font-size: 14px;
    color: @white;
    font-family: @primary-font;
  }

  &:hover {
    background-color: @white;
    border: 1px solid @primary-color;
    color: @primary-color;

    // transform: scale(0.95);
    span {
      color: @primary-color;
    }
  }

  &.mr-10 {
    @media screen and (max-width: 350px) {
      margin-right: 0px;
    }
  }
}

.ant-page-header {
  padding: 0px 0px;
}

// ag-grid comman css

// common modal

.commanModal {
  .ant-modal-close {
    position: absolute;
    top: 13px;
    right: 20px;

    .ant-modal-close-x {
      background-color: #91908e;
      width: 24px;
      height: 24px;
      border-radius: 50%;

      svg {
        position: absolute;
        top: 6px;
        right: 6px;
        height: 12px;
        width: 12px;

        path {
          fill: #fff;
        }
      }
    }
  }

  .ant-modal-header {
    border-top: 3px solid @primary-color;
    padding: 12px 20px;

    .ant-modal-title {
      .regulerFont();
      font-size: 20px;
      color: @font-color;
      letter-spacing: 0.5px;
    }
  }

  .ant-modal-body {
    padding: 25px 20px 25px 20px;

    form {
      .ant-form-item {
        margin-bottom: 12px;

        .ant-form-item-label {
          label {
            letter-spacing: 0.5px;
            .regulerFont();
            font-size: 14px;
            color: @modal-font-color;
          }
        }

        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            input {
              background-color: @input-color;
              border-radius: 3px;
              height: 38px;
              border: 1px solid #f4f5f7;
              padding: 0 12px;

              &:focus {
                box-shadow: 0px 0px 0px 1px @primary-color;
              }

              &::placeholder {
                color: rgb(139, 139, 139);
              }
            }

            .ant-radio-group {
              display: flex;
              width: 68%;
              justify-content: space-between;

              .ant-radio-wrapper {
                span {
                  .regulerFont();
                  font-size: 16px;
                  color: @modal-font-color;
                  padding-left: 10px;
                }

                .ant-radio {
                  padding-left: 0px;

                  .ant-radio-inner {
                    width: 20px;
                    height: 20px;
                    border-color: #d9d9d9;

                    &:after {
                      width: 12px;
                      height: 12px;
                      margin-top: -6px;
                      margin-left: -6px;
                    }
                  }
                }
              }
            }

            .ant-checkbox-group {
              .ant-checkbox-wrapper {
                span {
                  letter-spacing: 0.5px;
                  .regulerFont();
                  font-size: 16px;
                  color: @modal-font-color;
                }
              }
            }
          }
        }
      }

      .ant-select {
        border: 1px solid #f4f5f7;

        .ant-select-selector {
          border: none;
          background-color: @input-color;
          height: 38px;
          border-radius: 3px;
          padding: 0 12px;

          .ant-select-selection-overflow {
            .ant-select-selection-overflow-item {
              input {
                border: none !important;

                &:focus {
                  box-shadow: none;
                }
              }
            }
          }

          .ant-select-selection-item {
            line-height: 38px !important;
          }

          .ant-form-item-has-error {
            background-color: @input-color;
          }

          .ant-select-selection-placeholder {
            color: rgb(139, 139, 139);
          }
        }
      }

      .ant-form-item-has-error {
        .ant-select-selector {
          background-color: @input-color !important;
        }
      }

      .ant-select-focused {
        box-shadow: 0px 0px 0px 1px @primary-color;
        border-radius: 3px;
      }

      .ant-picker {
        border: none;
        background-color: @input-color;
        height: 38px;
        border-radius: 3px;
        width: 100%;
        border: 1px solid #f4f5f7;
        padding: 0 12px 0px 0px;

        .ant-picker-input {
          input {
            padding: 0 12px 0px 0px;
            color: @font-color;
            border: none !important;

            &:focus {
              box-shadow: none !important;
            }
          }
        }
      }

      .ant-picker-focused {
        box-shadow: 0px 0px 0px 1px @primary-color;
        border-radius: 3px;
      }

      .ant-form-item-explain-error {
        font-size: 13px;
        color: @error-color;
        padding: 5px 12px;
        letter-spacing: 0.5px;
        .boldFont();
      }

      h3 {
        .regulerFont();
        color: @font-color;
        font-size: 16px;
      }
    }

    .selectDown {
      .ant-form-item-extra {
        position: absolute;
        right: 7px;
        // bottom: 4px;
      }
    }

    #publishform {
      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            .ant-select {
              border: 1px solid #f4f5f7;

              .ant-select-selector {
                height: 38px;

                .ant-select-selection-search {
                  input {
                    height: 38px;
                    background-color: transparent !important;
                    padding: 0 0px !important;
                    border: none !important;

                    &:focus {
                      box-shadow: none !important;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .ant-col {
        h3 {
          letter-spacing: 1px;
          padding: 0px 10px;
        }
      }

      .warningImg {
        text-align: center;

        svg {
          height: 70px;
          width: 70px;

          g {
            path {
              stroke-width: 1;
            }
          }
        }
      }
    }
  }

  .ant-modal-footer {
    padding: 12px 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    button {
      height: 40px;
      padding: 6px 30px;
      border-radius: 3px;
      margin: 0px 12px 0px 0px;
    }

    .blackLineBtn {
      background-color: @primary-color;
      color: #fff;
      border: 1px solid transparent;
      .transition();
      height: 40px;
      padding: 6px 30px;

      span {
        color: @white;
        letter-spacing: 0.5px;
        .regulerFont();
        // font-size: 15px;
        .transition();
      }

      &:hover {
        border: 1px solid @primary-color;
        background-color: @white;

        span {
          color: @primary-color;
        }
      }
    }

    .orangeBgBtn {
      background-color: transparent;
      border: 1px solid #797979;
      .transition();
      height: 40px;
      padding: 6px 30px;

      span {
        color: @modal-font-color;
        letter-spacing: 0.5px;
        .regulerFont();
        // font-size: 15px;
        .transition();
      }

      &:hover {
        border: 1px solid @primary-color;

        span {
          color: @primary-color;
        }
      }
    }

    .disableBtn {
      border-color: transparent;
      padding: 6px 30px;
      height: 40px;

      span {
        color: @label-color;
      }
    }

    .redBgBtn {
      background-color: @error-color;
      color: #fff;
      height: 40px;
      border: 1px solid transparent;
      padding: 6px 30px;
      .transition();

      span {
        color: @white;
        letter-spacing: 0.5px;
        .regulerFont();
        font-size: 15px;
        .transition();
      }

      &:hover {
        border: 1px solid @error-color;
        background-color: @white;

        span {
          color: @error-color;
        }
      }
    }
  }
}

// common Drawer

.commanDrawer {
  .allowNumber {
    .ant-input-number {
      width: 100%;
      padding: 0;
      border: none;
      background-color: transparent;
    }
  }
  .ant-drawer-content-wrapper {
    @media screen and (max-width: 768px) {
      width: 100% !important;
    }
  }

  .ant-drawer-header {
    padding: 12px 20px;
    background-color: @white;
    border-top: 3px solid @primary-color;

    .ant-drawer-close {
      background-color: #91908e;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      position: absolute;
      right: 10px;

      .anticon-close {
        svg {
          position: absolute;
          top: 6px;
          right: 6px;
          height: 12px;
          width: 12px;

          path {
            fill: #fff;
          }
        }
      }

      svg {
        position: absolute;
        top: 6px;
        right: 6px;
        height: 12px;
        width: 12px;

        path {
          fill: #fff;
        }
      }
    }

    .ant-drawer-title {
      .regulerFont();
      font-size: 20px;
      letter-spacing: 0.5px;
      color: @font-color;
    }
  }

  .ant-drawer-body {
    form {
      .ant-form-item-label {
        label {
          .regulerFont();
          letter-spacing: 0.5px;
          font-size: 14px;
          color: @modal-font-color;
        }
      }

      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          input {
            background-color: @input-color;
            border-radius: 3px;
            height: 38px;
            border: 1px solid #f4f5f7;
            padding: 0 12px;
            &:focus {
              box-shadow: 0px 0px 0px 1px @primary-color;
            }

            &::placeholder {
              color: rgb(139, 139, 139);
            }
          }

          .ant-upload-list-item-card-actions-btn {
            opacity: 1;
          }

          .ant-radio-group {
            display: flex;
            // width: 68%;
            width: 64%;
            justify-content: space-between;

            .ant-radio-wrapper {
              span {
                .regulerFont();
                letter-spacing: 0.5px;
                font-size: 16px;
                color: @modal-font-color;
                padding-left: 10px;
              }

              .ant-radio {
                padding-left: 0px;

                .ant-radio-inner {
                  width: 20px;
                  height: 20px;
                  border-color: #d9d9d9;

                  &:after {
                    width: 12px;
                    height: 12px;
                    margin-top: -6px;
                    margin-left: -6px;
                  }
                }
              }
            }
          }

          .ant-checkbox-group {
            .ant-checkbox-wrapper {
              span {
                .regulerFont();
                letter-spacing: 0.5px;
                font-size: 16px;
                color: @modal-font-color;
              }
            }
          }
        }
      }

      .ant-input-status-error {
        background-color: @input-color !important;
      }

      .ant-select {
        background-color: @input-color;
        border: 1px solid #f4f5f7;

        .ant-select-selector {
          border: none;
          background-color: @input-color;
          height: 38px;
          border-radius: 3px;
          padding: 0 12px;

          .ant-select-selection-overflow {
            .ant-select-selection-overflow-item {
              input {
                border: none !important;

                &:focus {
                  box-shadow: none;
                }

                &::placeholder {
                  color: rgb(139, 139, 139);
                }
              }
            }
          }

          .ant-select-selection-search {
            input {
              background-color: transparent;
              padding: 0px 0px;
              border: none !important;

              &:focus {
                box-shadow: none;
              }

              &::placeholder {
                color: rgb(139, 139, 139);
              }
            }
          }

          .ant-form-item-has-error {
            .ant-select-selector {
              background-color: @input-color;
            }
          }

          .ant-select-selection-placeholder {
            color: rgb(139, 139, 139);
          }
        }
      }

      .ant-form-item-has-error {
        .ant-select-selector {
          background-color: @input-color !important;
        }
      }

      .ant-picker {
        border: none;
        background-color: @input-color;
        height: 38px;
        border-radius: 3px;
        width: 100%;
        padding: 0 12px;

        .ant-picker-input {
          input {
            color: #000;
            border: 1px solid transparent !important;

            &:focus {
              box-shadow: none !important;
            }

            &[disabled] {
              color: rgba(0, 0, 0, 0.25);
              background-color: #f5f5f5;
              box-shadow: none;
              cursor: not-allowed;
              opacity: 1;
            }
          }
        }

        &:hover {
          background-color: @input-color;
        }

        &::placeholder {
          color: rgb(139, 139, 139);
        }
      }

      .ant-picker-status-error {
        background-color: @input-color;
      }

      // .ant-picker-focused {
      //     box-shadow: 0px 0px 0px 1px @primary-color;
      //     border-radius: 3px;

      // }

      .ant-form-item-explain-error {
        font-size: 13px;
        color: @error-color;
        padding: 5px 12px;
        letter-spacing: 0.5px;
        .boldFont();
      }
    }

    #addEditLayoutForm {
      .ant-image {
        height: 165px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        // width:293px;
        .ant-image-img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }

      .ant-form-item {
        .ant-form-item-control {
          .ant-form-item-control-input {
            .ant-form-item-control-input-content {
              span {
                .ant-upload-list-item {
                  .ant-upload-list-item-info {
                    .ant-upload-list-item-name {
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .ant-form-item {
        .layoutOptions {
          .ant-upload-list {
            .ant-upload-list-item {
              background-color: @input-color;
              border-radius: 3px;
              height: 50px;
              border: 1px solid #f4f5f7;
              padding: 0 12px;

              &:hover {
                .ant-upload-list-item-info {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }

    .selectDown {
      .ant-form-item-extra {
        position: absolute;
        right: 7px;
        bottom: 4px;
      }
    }

    .formatRadioBtn {
      .ant-radio-group {
        width: 100% !important;
      }
    }
  }

  .ant-drawer-footer {
    padding: 12px 20px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    button {
      height: 40px;
      padding: 6px 30px;
      border-radius: 3px;
      margin: 0px 12px 0px 0px;
    }

    .blackLineBtn {
      background-color: @primary-color;
      color: #fff;
      height: 40px;
      border: 1px solid transparent;
      .transition();
      padding: 6px 30px;

      span {
        color: @white;
        .regulerFont();
        letter-spacing: 0.5px;
        // font-size: 15px;
        .transition();
      }

      &:hover {
        border: 1px solid @primary-color;
        background-color: @white;

        span {
          color: @primary-color;
        }
      }
    }

    .disableBtn {
      padding: 6px 30px;
      // height: 40px;

      span {
        color: @label-color;
      }
    }

    .orangeBgBtn {
      padding: 6px 30px;
      height: 40px;
      background-color: transparent;
      border: 1px solid #797979;
      .transition();

      span {
        color: @modal-font-color;
        letter-spacing: 0.5px;
        .regulerFont();
        // font-size: 15px;
        .transition();
      }

      &:hover {
        border: 1px solid @primary-color;

        span {
          color: @primary-color;
        }
      }
    }

    .redBgBtn {
      background-color: @error-color;
      color: #fff;
      padding: 6px 30px;
      height: 40px;
      border: 1px solid transparent;
      .transition();

      span {
        color: @white;
        letter-spacing: 0.5px;
        .regulerFont();
        // font-size: 15px;
        .transition();
      }

      &:hover {
        border: 1px solid @error-color;
        background-color: @white;

        span {
          color: @error-color;
        }
      }
    }

    .centerBtn {
      display: flex;
      justify-content: center;

      .orangeBgBtn {
        background-color: @primary-color;
        color: #fff;
        padding: 6px 30px;
        height: 42px;

        border: 1px solid transparent;
        .transition();

        span {
          color: @white;
          letter-spacing: 0.5px;
          .regulerFont();
          font-size: 16px;
          .transition();
        }

        &:hover {
          border: 1px solid @primary-color;
          background-color: @white;

          span {
            color: @primary-color;
          }
        }
      }
    }
  }

  .color-picker-input-design {
    margin-bottom: 12px;
    padding: 0px 12px;

    .ant-form-item-label {
      .regulerFont();
      color: @font-color;
      font-size: 15px;
    }

    .ant-form-item-control-input {
      background-color: @input-color;
      height: 38px;
      border: none;
      border-radius: 3px;

      .ant-form-item-control-input-content {
        height: 38px;

        div {
          box-shadow: none !important;
          height: 38px !important;
          border: 1px solid #f4f5f7 !important;
          border-radius: 3px;
        }
      }
    }
  }

  #publishLayouform {
    .ant-col-12 {
      @media screen and(max-width:600px) {
        display: block;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}

.ant-picker-calendar {
  padding: 0px 20px;

  .ant-picker-calendar-header {
    .ant-picker-calendar-year-select {
      height: 38px;

      .ant-select-selector {
        border-radius: 3px;
      }
    }

    .ant-picker-calendar-month-select {
      height: 38px;

      .ant-select-selector {
        border-radius: 3px;
      }
    }
  }

  .ant-radio-group {
    .ant-radio-button-wrapper {
      height: 38px;

      .ant-radio-button {
        height: 38px;
      }

      span {
        line-height: 38px;
      }
    }
  }
}

.ant-btn-primary[disabled],
// .ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  background-color: #d9d9d9;

  // &:hover {
  // 	span {
  // 		color: @white !important;
  // 	}
  // }
}

.settingContainer {
  .popUpSetting {
    .ant-form-item {
      margin-bottom: 0 !important;
    }

    .emergency-popup-checkbox {
      margin-bottom: 24px;
    }
    .emergency-popup-input {
      display: flex;
      align-items: flex-end;
      row-gap: 10px;
    }

    .ant-form-item-control {
      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          input {
            background-color: @input-color;
            border-radius: 3px;
            height: 38px;
            border: 1px solid #e9e9e9;
            padding: 0 12px;

            &:focus {
              border: 1px solid @primary-color;
            }
          }
        }
      }
    }

    .settingSaveBtn {
    //   .marginTopButton {
    //     margin-top: 10px;
    //   }
      @media screen and (max-width: 1350px) {
        button {
          margin-top: 10px;
        }
      }
      .disableBtn {
        span {
          color: #7c7c7c;
        }
      }
    }

    @media screen and (max-width: 1350px) {
      .ant-row {
        .ant-col-6 {
          display: block;
          flex: 0 0 50%;
          max-width: 50%;
        }
      }
    }

    @media screen and (max-width: 480px) {
      .ant-row {
        .ant-col-6 {
          display: block;
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  }
}

.tokenLayout {
  .ant-form-item-control {
    .ant-form-item-control-input {
      .ant-form-item-control-input-content {
        input {
          background-color: @input-color;
          border-radius: 3px;
          height: 38px;
          border: 1px solid #e9e9e9;
          padding: 0 12px;

          &:focus {
            border: 1px solid @primary-color;
          }
        }
      }
    }
  }

  .ant-col-6 {
    @media screen and(max-width:800px) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @media screen and(max-width:400px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

// footer
footer {
  &.ant-layout-footer {
    padding: 15px 35px;
    display: flex;
    align-items: center;
    .transition();
    justify-content: space-between;
    flex-wrap: wrap;
    // box-shadow: 0px 8px 20px 3px rgba(170,170,185,0.3);

    div {
      .regulerFont();
      font-size: 14px;
      color: @font-color;
      // letter-spacing: 0.5px;

      @media screen and (max-width: 768px) {
        font-size: 12px;
      }

      @media screen and (max-width: 350px) {
        font-size: 8px;
      }
    }

    @media screen and (max-width: 600px) {
      // justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    @media screen and (max-width: 400px) {
      padding: 15px 15px;
    }
  }
}

.ant-breadcrumb {
  display: none;
}

.infoModalTable {
  border: 1px solid @layout-bg-color;

  .logTableTitle {
    border-bottom: 1px solid @layout-bg-color;
    padding-bottom: 10px;
    margin-bottom: 10px;

    h4 {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 0;
    }

    span {
      font-size: 14px;
      font-weight: 700;
    }
  }

  .infoData {
    padding: 15px;
    border-right: 1px solid @layout-bg-color;
    height: 100%;
  }
}

.addLicense,
.editLicense {
  .selectDown {
    .ant-form-item-control-input {
      .ant-form-item-control-input-content {
        .ant-select {
          .ant-select-selector {
            padding: 0px 0px 0px 12px;
            border-color: transparent;

            .ant-select-selection-search {
              width: auto;

              input {
                background-color: transparent !important;
                border: none !important;
                padding: 0px 0px !important;

                &:focus {
                  box-shadow: none !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .ant-input-number {
    width: 100%;

    .ant-input-number-input-wrap {
      input {
        padding: 0px 0px !important;
        border: none !important;
        height: 36px !important;

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    &.ant-number-focused {
      box-shadow: 0 0 0 1px rgba(19, 185, 243, 0.9) !important;
    }
  }
}

.animated-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #f5c538;
  height: 0;
  color: black;
  font-size: 16px;
  font-weight: 900;
  animation: heightAnimation 1s ease forwards;
}

@keyframes heightAnimation {
  0% {
    height: 0;
  }

  100% {
    height: 36px;
  }
}

.buildYourOwnLayoutDrawer {
  .ant-drawer-close {
    display: none;
  }

  .ant-drawer-body {
    padding: 0;
  }
}

.layoutBtn {
  display: inline;
  button {
    margin-left: 10px;
  }
  .cancelBt {
    background: transparent;
    text-shadow: none;
    box-shadow: none;
    font-size: 14px;
    height: auto;
    // color: @error-color;
    letter-spacing: 0.5px;
    padding: 8px 25px;
    border-radius: 3px;
    // border: 1px solid @primary-color;
    // border:1px solid @error-color;
  }
}
.ownLayoutBtn {
  background-color: #393e42;
}
.layout_perm {
  display: flex;
  flex-direction: column;
}
.AddBtnWrap {
  display: block;
  font-size: 14px;
  text-transform: capitalize;
  .ant-btn {
    width: auto;
    display: block;
    margin: 5px 0 10px;
    background: #1bb2e7;
    color: #fff;
    border: none;
    border-radius: 5px;
    span {
      font-size: 22px;
      line-height: 1.1;
    }
  }
  .ant-space-horizontal {
    width: 100%;
    .anticon-close {
      background-color: #f00;
      border-radius: 20px;
      padding: 5px;
      color: #fff;
      font-size: 10px;
      margin-top: 18px;
    }
  }
}

.alignC {
  .ant-drawer-body {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
.ant-tooltip-placement-bottomRight {
  padding-top: 0 !important;
}
.ant-popover-placement-rightBottom {
  .ant-popover-inner-content {
    > div {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: 5px;
      a {
        &:hover {
          color: #000;
          text-decoration: underline;
        }
      }
    }
  }
}

.ant-popover-placement-top {
  .ant-popover-title {
    a {
      &:hover {
        color: #000 !important;
        text-decoration: underline !important;
      }
    }
  }
}

.commanDrawer {
  .withoutBorder {
    .ant-form-item {
      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          .ant-input-number {
            width: 100%;
            input {
              border: none !important;
              background-color: transparent;
              padding: 0;
              box-shadow: none !important;
              &:focus {
                border: none !important;
              }
            }
          }
        }
      }
    }
  }
}

.ant-page-header-content {
  .ag-grid-wrapper {
    &.ag-theme-alpine {
      .ag-root-wrapper {
        .ag-header {
          .ag-header-cell {
            &.headerLastLive {
              background-color: @primary-color;
              .ag-header-cell-text {
                color: @white;
              }
            }
          }
        }
        .ag-body-viewport {
          .ag-row {
            .ag-cell {
              &.lastLiveTime {
                background-color: @primary-color;
                color: @white;
              }
            }
          }
        }
      }
    }
  }
}

.ant-popover-placement-top {
  .ant-popover-title {
    > div {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: 5px;
    }
  }
}

.ant-popover {
  .ant-popover-inner-content {
    > div:not(.sketch-picker) {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: 5px;
      a {
        &:hover {
          color: #000 !important;
          text-decoration: underline !important;
        }
      }
    }
  }
  .sketch-picker {
    width: 230px !important;
  }
}
.main__app__content {
  .lastLiveTime {
    span {
      display: block;
    }
  }
}

.addRemove {
  display: flex;
  align-items: center;
  gap: 5px;
}

.mixLayout {
  .layoutNameIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    vertical-align: middle;
    position: relative;
    margin: 10px 0;
    padding: 10px 0;
    height: inherit;
    color: rgba(0, 0, 0, 0.88);
    font-weight: normal;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.02);
    border: 1px solid #d9d9d9;
    border-radius: 15px 0 0 15px;
    transition: all 0.3s;
    line-height: 1;
    box-shadow: none;
    opacity: 1;
  }
  .layoutName,
  .addRemove {
    background-color: #f3f5f7 !important;
    margin: 10px 0;
    padding: 10px 0;
    .ant-input-number {
      width: 100%;
      padding: 0;
      border: none;
      background-color: transparent;
    }
  }
}

.generatedImages {
  min-height: 30px;
  .column {
    margin-top: 1rem;
  }
  .container {
    position: relative;
    padding: 0;
    .checkboxAi {
      z-index: 1;
      position: absolute;
      top: 0px;
      left: 5px;
    }
    .imageAi {
      z-index: 0;
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }

  .spin,
  p {
    margin-top: 1rem;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}

.ck-editor__editable_inline ul li {
  margin-left: 15px !important;
}

.removeUploadModal {
  .ant-btn {
    height: auto;
    padding: 8px 25px;
  }
  .cropperCol {
    height: 48vh;
    width: 48vw;
    .urlImage {
      background-color: #a09999;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// To set the min height of ckeditor
.ck-editor__editable_inline {
  min-height: 200px;
}

.ant-upload-list-picture {
  .ant-upload-list-item-thumbnail {
    display: flex;
    align-items: center;
    overflow: hidden;
    img {
      height: auto;
    }
  }
}

.custom-date-filter a {
  position: absolute;
  right: 20px;
  color: #999;
  cursor: pointer;
}
.custom-date-filter:after {
  position: absolute;
  content: "\f073";
  display: block;
  font-weight: 400;
  font-family: "Font Awesome 5 Free";
  right: 5px;
  pointer-events: none;
  color: #999;
}

.scheduleCalenderTooltip {
  display: flex;
  justify-content: flex-end !important;
  .buttons {
    cursor: pointer;
  }
  .anticon {
    margin: 0 5px 0 5px;
  }
}

.ant-tooltip {
  &.ant-tooltip-placement-top {
    z-index: 2;
  }
}

.schedule-layout,
.publishLayout {
  .ant-table {
    .ant-table-tbody {
      tr {
        .ant-table-cell {
          a {
            cursor: default;
            color: @text-color;
          }
        }
      }
    }
  }
}
.publishLayout {
  .ant-table,
  .onceTypeDate {
    margin-top: 1rem;
  }
}

.deviceStartupDuration {
  .startupCheckBox {
    margin-bottom: 0 !important;
  }
}

// To hide logo in ck editor
.ck-powered-by {
  display: none !important;
}
