.dark__layout{
	::-webkit-scrollbar {
		width: 5px;
		background-color: transparent;
		border: 0;
	}
	
	/* Track */
	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.01);
		-webkit-border-radius: 3px;
		border-radius: 3px;
		background-color: #414141;
	}
	
	/* Handle */
	::-webkit-scrollbar-thumb {
		-webkit-border-radius: 3px;
		border-radius: 3px;
		background: #000000;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.01);
	}
	
	::-webkit-scrollbar-thumb:window-inactive {
		background:#000000;
	}
	

	.main__page__appsidebar {
		height: 100vh;
		background: @layout-sidebar-dark-background;
		.transition();
	
		.ant-menu {
			height: calc(100vh - (@layout-header-height + 20px));
			overflow-y: auto;
			padding: 0 20px;
			&.ant-menu-dark {
				background: transparent;
				padding: 15px 15px 32px;
			}
	
			.ant-menu-item {
				.transition();
				padding: 10px 12px !important;
				margin: 8px 0 !important;
				border-radius: 3px;
	
				.ant-menu-title-content {
					font-size: 15px;
					letter-spacing: 0.5px;
					.regulerFont();
	
					span{
						&.counter{
							.boldFont();
							background-color:@dark-blue-color;
							color:@white;
							font-size:12px;
							height:20px;
							// width:20px;
							padding:5px 7px;
							border-radius:10px;
							display: flex;
							align-items: center;
							justify-content: center;
							margin:0px 3px;
						}
						.extracontent{
							display: flex;
							align-items: center;
							justify-content: center;
							flex-direction:row;
						}
					}
	
				}
	
				&:not(:last-child) {
					margin-bottom: 12px;
				}
	
				// .ant-menu-item-icon {
	
				// 	opacity: 0.6;
				// 	transition: all 0.5s ease;
				// }
	
				&:hover,
				.ant-menu-item-selected {
					.ant-menu-item-icon {
						opacity: 1;
					}
				}
	
				&:hover {
					.ant-menu-title-content {
						span {
							opacity: 0.8;
						}
					}
	
					svg {
						g {
							path {
								opacity: 0.8;
							}
						}
					}
	
	
				}
	
			}
	
			.ant-menu-submenu {
				padding-left: 0px;
				border-radius: 3px;
				.ant-menu-sub {
					height: auto;
				}
				.ant-menu-item-icon {
	
					opacity: 0.6;
				}
	
				.ant-menu-submenu-title {
					font-size: 17px;
					.regulerFont();
					padding-left: 12px !important;
				}
	
				&.ant-menu-submenu-open {
					background-color: transparent;
	
					.ant-menu-item-icon {
						opacity: 1;
					}
	
					.ant-menu-submenu-title {
						background-color: transparent;
						border-radius: 3px;
	
					}
	
					.ant-menu-sub {
						list-style: none;
						background: transparent;
						padding: 0 20px;
	
						.ant-menu-item {
							margin-bottom: 0 !important;
							padding: 10px 5px !important;
	
							// &:before {
							// 	content: "";
							// 	width: 6px;
							// 	height: 6px;
							// 	background-color: @white;
							// 	display: inline-block;
							// 	border-radius: 50%;
							// 	position: absolute;
							// 	left: 0;
							// 	top: 17px;
							// }
							&.ant-menu-item-selected {
								background-color:transparent;
								color: @white;
	
								svg {
									path {
										fill: @white;
									}
								}
							}
				
	
							// svg {
							// 	margin-top: 15px;
							// }
	
							.ant-menu-title-content {
								margin-left: 9px;
							}
						}
	
	
					}
				}
	
	
			}
	
			.ant-menu-title-content {
				margin-left: 15px;
	
				span {
					display: flex;
					align-items: center;
					justify-content: space-between;
					.regulerFont();
					font-size: 15px;
					color: @white;
	
					.sidebarArrowIcon {
						svg {
							height: 16px;
							width: 16px;
						}
					}
				}
	
			}
	
			svg {
				height: 19px;
				width: 19px;
	
				g {
					path {
						fill: @white;
					}
				}
			}
	
			.ant-menu-item {
				&.ant-menu-item-active {
					background-color:rgba(33, 102, 126,0.3)!important;
					color: @primary-color !important;
	
					svg {
						opacity: 1;
	
						path {
							fill:@primary-color;
						}
					}
					.ant-menu-title-content{
						span{
							color:@primary-color;
							.extracontent{
								.counter{
									color:@white;
								}
							}
						}
					}
				}
			}
			&.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
				background-color: transparent;
			}
			
		}
	
		&.ant-layout-sider-collapsed {
			.logo__wrapper {
				span {
					display: none;
				}
			}
	
			.ant-menu .ant-menu-item {
				padding: 4px 12px !important;
				width: 43px;
			}
		}
	
	
	
		&.ant-layout-sider-light {
			background: @layout-sidebar-background;
	
			.ant-menu-dark {
				.ant-menu-item {
					.ant-menu-title-content {
						span {
							color: @primary-color;
						}
						span{
							&.counter{
							 background-color:orange;
							 color:@white;	
							}
							}
					}
					svg{
						g{
							path{
								fill:@primary-color;
							}
						}
					}
				
	
				}
	
				.ant-menu-item-active {
					background-color: @primary-color;
					border-radius: 3px;
					color: @white;
	
					.ant-menu-title-content {
						span {
							color: @white;
						}
						.extracontent{
							span{
								color: @white !important;
							}
						}
					}
					svg{
						g{
							path{
								fill:@white;
							}
						}
					}
				}
			}
	
			.logo__wrapper {
				cursor: pointer;
				background-color: @white;
			}
	
		}
		.logo__wrapper {
			cursor: pointer;
			// padding: 5px @gutter;
			// padding: 20px @gutter ;
			padding:12px 12px;
			// border-bottom: 1px solid rgba(255, 255, 255, 0.5);
			// border-right: 1px solid rgba(255, 255, 255, 0.8);
			background-color: @layout-sidebar-dark-background;
			height: @layout-header-height;
			.transition();
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
		  
			img {
			  max-height: 100%;
			}
		  
			span {
			  color: #fff;
			}
		  
			&:after {
			  position: absolute;
			  width: 100%;
			  height: 1px;
			//   background-color:#bdb9b5;
			
			  content: "";
			  bottom: 0;
			}
			// box-shadow: 0px 4px 32px 0px rgba(48, 48, 72,0.2);
			box-shadow: 0px 3px 10px 0px rgba(116, 117, 118, 0.32);
			border-bottom-right-radius:10px;
		  }
	}
}

.light__layout{
	::-webkit-scrollbar {
		width: 5px;
		background-color: transparent;
		border: 0;
	}
	
	/* Track */
	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(82, 78, 78, 0.01);
		box-shadow: inset 0 0 6px rgba(82, 78, 78, 0.01);
		-webkit-border-radius: 3px;
		border-radius: 3px;
		background: #e9e9e9;
	}
	
	/* Handle */
	::-webkit-scrollbar-thumb {
		-webkit-border-radius: 3px;
		border-radius: 3px;
		background: #a09999;
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.01);
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.01);
	}
	
	::-webkit-scrollbar-thumb:window-inactive {
		background: #a09999;
	}
	

	.main__page__appsidebar {
		background: @layout-sidebar-dark-background;
		.transition();
	.ant-layout-sider-children{
		box-shadow: 0px 8px 20px 3px rgba(170,170,185,0.3)
	}
		.ant-menu {
			height: calc(100vh - (@layout-header-height + 20px));
			overflow-y: auto;
			padding: 0 20px;
			background-color: transparent;
			&.ant-menu-dark {
				background: transparent;
				padding: 15px 15px 32px;
			}
	
			.ant-menu-item {
				.transition();
				padding: 10px 12px !important;
				margin: 8px 0 !important;
				border-radius: 3px;
	
				.ant-menu-title-content {
					font-size: 15px;
					letter-spacing: 0.5px;
					.regulerFont();
					color:#000;
	
					span{
						&.counter{
							.boldFont();
							background-color:#ebebeb;
							color:#242323;
							font-size:12px;
							height:20px;
							// width:20px;
							padding:5px 7px;
							border-radius:10px;
							display: flex;
							align-items: center;
							justify-content: center;
							margin:0px 3px;
						}
						.extracontent{
							display: flex;
							align-items: center;
							justify-content: center;
							flex-direction:row;
						}
					}
	
				}
	
				&:not(:last-child) {
					margin-bottom: 12px;
				}
	
				// .ant-menu-item-icon {
	
				// 	opacity: 0.6;
				// 	transition: all 0.5s ease;
				// }
	
				&:hover,
				.ant-menu-item-selected {
					.ant-menu-item-icon {
						opacity: 1;
					}
				}
	
				&:hover {
					.ant-menu-title-content {
						span {
							opacity: 0.8;
						}
					}
	
					svg {
						g {
							path {
								opacity: 0.8;
							}
						}
					}
	
	
				}
	
			}
	
			.ant-menu-submenu {
				padding-left: 0px;
				border-radius: 3px;
				.ant-menu-submenu-arrow{ 
					top: 40%;
					&::before 
					{
						background-color: @light-svg;
						width: 12px;
						transform: rotate(45deg) translateX(5px);
					}
					&::after{
						background-color: @light-svg;
						width: 12px;
						transform: rotate(-45deg) translateX(-5px);
					}
				}
				.ant-menu-item-icon {
	
					opacity: 0.6;
					path{
						fill: @light-svg;
					}
				}
	
				.ant-menu-submenu-title {
					font-size: 17px;
					.regulerFont();
					padding-left: 12px !important;
				}
	
				&.ant-menu-submenu-open {
					background-color: #625e5e1a;
					.ant-menu-submenu-arrow{
						transform: translateY(6px);
						&::before 
						{
							background-color: @light-svg;
							width: 12px;
							transform: rotate(-45deg) translateX(5px);
						}
						&::after{
							background-color: @light-svg;
							width: 12px;
							transform: rotate(45deg) translateX(-5px);
						}
					}
					.ant-menu-submenu-arrow{
						&::before, &::after{
							background-color: @white;
						}
					}
					.ant-menu-submenu-title{
						.ant-menu-title-content{
							color: @white;
						}
					 .ant-menu-item-icon {
						opacity: 1;
						 path{
							fill: @white;
						}
					}
				}
					.ant-menu-submenu-title {
						background-color: @primary-color;
						border-radius: 3px;
	
					}
	
					.ant-menu-sub {
						list-style: none;
						background: transparent;
						padding: 0 20px;
						height: auto;
						.ant-menu-item {
							margin-bottom: 0 !important;
							padding: 10px 5px !important;
	
							// &:before {
							// 	content: "";
							// 	width: 6px;
							// 	height: 6px;
							// 	background-color: @white;
							// 	display: inline-block;
							// 	border-radius: 50%;
							// 	position: absolute;
							// 	left: 0;
							// 	top: 17px;
							// }
							// &.ant-menu-item-selected {
							// 	background-color:transparent;
							// 	color: @white;
	
							// 	svg {
							// 		path {
							// 			fill: @white;
							// 		}
							// 	}
							// }
							.ant-menu-title-content {
								margin-left: 9px;

							}
							&.ant-menu-item-selected{
								.ant-menu-item-icon{
									opacity: 1;
									
										g {
											path{
												fill: @primary-color;
												opacity: 1;
											}
										}
									
								}
								.ant-menu-title-content{
									span{
										color: @primary-color;
									}
								}
							}
						}
	
	
					}
				}
	
	
			}
	
			.ant-menu-title-content {
				margin-left: 15px;
	
				span {
					display: flex;
					align-items: center;
					justify-content: space-between;
					.regulerFont();
					font-size: 15px;
					color: @white;
	
					.sidebarArrowIcon {
						svg {
							height: 16px;
							width: 16px;
						}
					}
				}
	
			}
	
			svg {
				height: 19px;
				width: 19px;
	
				g {
					path {
						fill: @white;
					}
				}
			}
	
			.ant-menu-item {
				&.ant-menu-item-active {
					background-color: @primary-color !important;
					color: @white !important;
	
					svg {
						opacity: 1;
	
						path {
							fill: @white;
						}
					}
				}
			}
			&.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
				background-color: transparent;
			}
		}
	
		&.ant-layout-sider-collapsed {
			.logo__wrapper {
				span {
					display: none;
				}
			}
	
			.ant-menu .ant-menu-item {
				padding: 4px 12px !important;
				width: 43px;
			}
		}
	
	
	
		&.ant-layout-sider-dark {
			background: @layout-sidebar-background;
	
			.ant-menu-dark {
				.ant-menu-item {
					.ant-menu-title-content {
						span {
							color:@light-sidebar-font-color;
						}
						span{
							&.counter{
							 background-color:@dark-blue-color;
							 color:@white;	
							}
							}
					}
					svg{
						g{
							path{
								fill:@light-svg;
							}
						}
					}
				
	
				}
	
				.ant-menu-item-active {
					background-color: @primary-color;
					border-radius: 3px;
					color: @white;
	
					.ant-menu-title-content {
						span {
							color: @white;
						}
					}
					svg{
						g{
							path{
								fill:@white;
							}
						}
					}
				}
				.ant-menu-submenu {
					.ant-menu-submenu-title {
						span {
							color:@light-sidebar-font-color;
						}
						span{
							&.counter{
							 background-color:@dark-blue-color;
							 color:@white;	
							}
							}
					}
				}
			}
	
			.logo__wrapper {
				cursor: pointer;
				background-color: @white;
			}
	
		}
		.logo__wrapper {
			cursor: pointer;
			// padding: 5px @gutter;
			// padding: 20px @gutter ;
			padding:12px 12px;
			// border-bottom: 1px solid rgba(255, 255, 255, 0.5);
			// border-right: 1px solid rgba(255, 255, 255, 0.8);
			background-color: @white;
			height: @layout-header-height;
			.transition();
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
		  
			img {
			  max-height: 100%;
			}
		  
			span {
			  color: #fff;
			}
		  
			&:after {
				position: absolute;
				width: 100%;
				height: 1px;
				background-color: #dddddd;
				content: "";
				bottom: 0;
			}
		  }
	}
}

.ant-menu-submenu {
	padding-left: 0px;
	border-radius: 3px;
	.ant-menu-submenu-arrow{ 
		top: 40%;
		&::before 
		{
			background-color: @light-svg;
			width: 12px;
			transform: rotate(45deg) translateX(5px);
		}
		&::after{
			background-color: @light-svg;
			width: 12px;
			transform: rotate(-45deg) translateX(-5px);
		}
	}
	&.ant-menu-submenu-open {
		background-color: #625e5e1a;
		.ant-menu-submenu-arrow{
			transform: translateY(6px) !important;
			&::before 
			{
				background-color: @light-svg;
				width: 12px;
				transform: rotate(-45deg) translateX(5px) !important;
			}
			&::after{
				background-color: @light-svg;
				width: 12px;
				transform: rotate(45deg) translateX(-5px) !important;
			}
		}
	}
}




.ant-menu-submenu-popup {

	.ant-menu-vertical.ant-menu-sub,
	.ant-menu-vertical-left.ant-menu-sub,
	.ant-menu-vertical-right.ant-menu-sub {
		max-height: initial;
		li{
			display: flex;
			.ant-menu-item-icon{
				width: 20px;
			}
			span{
				color: @text-color;
			}
		}
	}
}
.ag-floating-bottom-viewport{
	.ag-cell{
		border-left: 1px solid #dde2eb;
	}
}