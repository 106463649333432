.pricingPage {
    margin-top: 30px;
    .titleSection {
        h1 {
            font-size: 32px;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            margin-bottom: 10px;
        }
        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0.32px;
            max-width: 386px;
        }
    }
    .ant-tabs-content-holder {
        margin-top: 30px;
    }
    .ant-tabs > .ant-tabs-nav {
        flex-direction: row-reverse;
        align-items: flex-start;
        flex-wrap: wrap;
        &::before {
            display: none;
        }
        .ant-tabs-nav-wrap {
            align-items: flex-start;
            justify-content: flex-end;
        }
        .ant-tabs-nav-list {
            padding: 12px;
            background: #ffffff;
            height: auto;
            border-radius: 100px;
        }
    }
    .ant-tabs-tab {
        border-radius: 100px;
        border: 1px solid #1381A8;
        background: #FFF;
        box-shadow: 0px 2px 7px 0px rgb(112 112 112 / 25%);
        min-width: 144px;
        height: 48px;
        text-align: center;
        justify-content: center;
        + .ant-tabs-tab {
            margin: 0 0 0 12px;
        }
        &.ant-tabs-tab-active {
            border: 1px solid #1381A8;
            background: #1BB2E7;
            .ant-tabs-tab-btn {
                color: #fff;
            }
        }
    }
    .ant-tabs-ink-bar {
        display: none;
    }
    .pricingWrap {
        border: 1px solid #E5E5E5;
        background-color: #fff;
        .pricingRow {
            &:last-child{
                .featureData{
                    &:last-child{
                        .most_popular{
                            border-bottom: 1px solid #1381A8;
                        }
                    }
                }
            }
            display: flex;
            flex-direction: column;
            // flex-wrap: wrap;
            &.pricingMainRow {
                flex-direction: row;                
            }
            // &:last-child {
            //     .featureData {
            //         .featureTick.most_popular {
            //             border-bottom: 2px solid #22b3e4;    
            //         }
            //     }
            // }
            .priceBox {
                display: flex;
                flex: 1;
                position: relative;
                border-left: 1px solid #E5E5E5;
                // border-bottom: 1px solid #E5E5E5;
                &.most_popular {
                    border-top: 2px solid #22b3e4;
                    border-left: 2px solid #22b3e4 !important;
                    border-right: 2px solid #22b3e4;
                    .most_popular_text {
                        display: block;
                    }
                }
                &:first-child {
                    border-left: 0
                }
                .firstLevel {
                    padding: 30px 15px;
                    width: 100%;
                    .planName {
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        color: #2a2a2a;
                        margin-bottom: 18px;
                    }
                    .priceText {
                        color: #000;
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 38px;
                        span {
                            font-size: 11px;
                        }
                    }
                    .getStartedBtn {
                        border-radius: 8px;
                        background: linear-gradient(90deg, #1BB2E7 30.98%, #1381A8 100%);
                        width: 100%;
                        border: 0;
                        color: #fff;
                        text-transform: uppercase;
                        font-size: 14px;
                        line-height: normal;
                        font-weight: 700;
                        height: 40px;
                        margin-top: 26px;
                    }
                    &.pricePlans{
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        justify-content: center;
                        font-size: 32px;
                        font-weight: 700;
                        text-transform: uppercase;
                        color: #fff;
                        letter-spacing: 1px;
                        text-shadow: 0px 3px #107397;
                        background: linear-gradient(90deg, #1BB2E7 30.98%, #1381A8 100%);
                    }
                }
                .most_popular_text {
                    font-size: 12px;
                    height: 20px;
                    line-height: 18px;
                    white-space: nowrap;
                    display: inline-block;
                    position: absolute;
                    top: -12px;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: #22b3e4;
                    color: #fff;
                    font-weight: 600;
                    padding: 1px 10px;
                    border-radius: 20px;
                    display: none;
                }
            }
            .featureHeading {
                display: flex;
                flex-wrap: wrap;        
                width: 100%;
                background-color: #fbfbfb;
                font-size: 16px;
                font-weight: 600;
                .keyFeature,
                .featureTick {
                    flex: 1 1;
                    padding: 10px;
                    border-top: 1px solid #E5E5E5;
                    border-bottom: 1px solid #E5E5E5;
                    border-left: 1px solid transparent;
                    &.most_popular {
                        border-left: 2px solid #22b3e4 !important;
                        border-right: 2px solid #22b3e4;
                    }
                    img {
                        width: 20px;
                    }
                }
                .keyFeature {
                    border-left: 0;
                }
            }
            .featureData {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                .keyFeature, 
                .featureTick {
                    flex: 1;
                    padding: 10px;
                    border-left: 1px solid #E5E5E5;
                    // border-bottom: 1px solid #E5E5E5;
                    &.most_popular {
                        border-left: 2px solid #22b3e4 !important;
                        border-right: 2px solid #22b3e4;
                    }
                    img {
                        width: 20px;
                    }
                }
                .featureTick {
                    text-align: center;
                }
                .keyFeature {
                    display: flex;
                    justify-content: space-between;
                    border-left: 0;
                }
            }
            &:last-child {
                .featureData {
                    .keyFeature,
                    .featureTick {
                        border-bottom: 0;
                    }
                }
            }
        }
                
        @media (max-width: 1260px) {
            .pricingRow {
                .featureHeading {
                    .featureTick {
                        display: none;
                    }
                }
                .featureData {
                    .keyFeature {
                        width: 100%;
                        flex: 100%;
                    }
                    .featureTick {
                        border-top: 1px solid #E5E5E5;
                        border-bottom: 1px solid #E5E5E5;
                        &:nth-child(2) {
                            border-left: 0;
                        }
                        &.most_popular {
                            border-left: 1px solid #E5E5E5 !important;
                            border-right: 0;
                        }
                    }
                    &:last-child {
                        .featureTick {
                            border-bottom: 0;
                        }
                    }
                }
                .priceBox.most_popular {
                    border: 0;
                    border-left: 1px solid #E5E5E5;                    

                }
                &.pricingMainRow {
                    .priceBox {
                        &:first-child {
                            display: none;
                        }
                    }
                }
                &:last-child {
                    .featureData .featureTick.most_popular {
                        border-bottom: 0;
                    }
                }
            } 
        }        
    }

    @media (max-width: 1340px) {
        .ant-tabs > .ant-tabs-nav {
            flex-direction: column-reverse;
            .ant-tabs-nav-wrap {
                width: 100%;
                &:before,
                &:after {
                    display: none;
                }
                .ant-tabs-nav-list {
                    transform: none !important;
                }
                .ant-tabs-tab {
                    min-width: 110px;
                    height: 40px;
                }
            }
            .ant-tabs-nav-operations {
                display: none;
            }    
            .ant-tabs-extra-content {
                width: 100%;
            }
        }
    }    
}

.dark__layout {
    .pricingPage {
        .titleSection {
            color: #fff;
            h1 {
                color: #fff;
            }
        } 
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
            background: @layout-sidebar-dark-background;
        }
        .pricingWrap {
            background-color: #23282c;
            border: 1px solid @dark-layout-bg;
            .pricingRow {
                .priceBox {
                    border-left: 1px solid @dark-layout-bg;
                    .firstLevel {
                        .planName,
                        .priceText {
                            color: #fff;
                        }                        
                    } 
                } 
                .featureHeading {
                    background-color: #23282c;
                    color: #fff;
                    .keyFeature,
                    .featureTick {
                        border-top: 1px solid @dark-layout-bg;
                        border-bottom: 1px solid @dark-layout-bg;
                    }
                }
                .featureData {
                    color: #fff;
                    .featureTick {
                        border-left: 1px solid @dark-layout-bg;
                    }
                }
            } 
            
        } 
    } 
}
.topPlanDetail {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    h1 {
        font-size: 24px;
    }
    .planName {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #ddd;
        padding-bottom: 5px;
        margin-bottom: 12px;
        span {
            font-size: 20px;
            color: #b5adad;
        }
    }
    .planTotalAmt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            font-size: 20px;
            color: #2a2a2a;
        }
    } 
}
.paymentCardDetail label {
    margin-bottom: 5px;
    display: block;
}

.paymentDetailForm .form_submit_button {
    width: 100%;
    margin-top: 15px;
    font-weight: 600;
    font-size: 18px;
}

.subsFontSize {
    font-size: 18px;
    font-weight: 600;
}

#addEditRateForm {
    .paymentCardDetail {
        label{
            display: flex;
            flex-wrap: nowrap;
            
        }
    }
}