// @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap");

// orange:#F06724
// dark skyblue:#1D76BB

@primary-color:#1bb2e7;
@dark-blue-color:#1D76BB;
@secondary-color: #71bf44;
@font-color:rgb(35, 33, 30);
@sideBar-font-color:#f0f0f0;
@layout-bg-color:#f3f5f7;
@ag-input-color:#f7f7f7;
// @input-color:#f4f5f7;
@input-color:#fbfbfb;
@modal-font-color:#181c32;
@svg-color:#696b71;
@black: #000;
// @white: #f0f0f0;
@white : #fff;
@line-color:#f4f4f4;
@light-green-color:#ecfaf2;
@green-color:#4fcb77;
@light-blue-color:#e6f5ff;
@blue-color:#8fd0ff;
@light-purple-color:#f8f0fb;
@purple-color:#bb6ddd;
@light-pink-color:#fff2f2;
@pink-color:#ff5e5e;
@success-color: #52c41a;
@warning-color: #faad14;
@error-color: #ff5555;
@label-color: #7c7c7c;
// @primary-font: "Montserrat", sans-serif;
@header-font-color: #181c32;
@gutter: 25px;
@text-color: #2a2a2a;
@dark-header-navigation:#181c1f;
@dark-border: #414141;
@dark-even-row:#262b2e;
@dark-odd-row:#393e42;
@light-svg:#afb2b4;
@light-sidebar-font-color:#2d2e30;
 

// Main Header Variables
@layout-body-background: @white;
@layout-header-background: @white;
@layout-header-color: @text-color;
// @layout-sidebar-dark-background: #23211e;
@layout-sidebar-dark-background:#23282c;
@layout-sidebar-background:@white;
@layout-header-height: 70px;
@dark-layout-bg: #101113;

// Action Button Color variables
@orange-btn: #ffa500;
@red-btn: #f10000;
@blue-btn: #0f01ff;

// Main Header Logo Variables
@logo-height: 42px;

// Section Variables
@section-padding: 50px;
@screen-md: 768px;
@screen-lg: 992px;
@screen-xl: 1200px;
@screen-xxl: 1600px;


//  font-family

@primary-font:'Lato', sans-serif;
@secondary-font:'Open Sans', sans-serif;

.regulerFont{
  font-family:@primary-font;
  font-weight:400;
}

.boldFont{
  font-family:@primary-font;
  font-weight:700;
}

// transition
.transition{
  transition:0.50s all linear;
}

.transitionControl(@time,@effect){
 transition:@time all @effect;
}

.themeColorOpacity(@rgbColor, @opacity) {
  background-color: rgba(@rgbColor, @opacity);
}










































