.ant-layout {
    .main__page__appheader {
        .headerWrap {
            .appheader__right {
                display: flex;
                align-items: center;

                .notificationIcon {
                    padding-right: 15px;
                    .transition();

                    svg {
                        .transition();

                        &:hover {
                            path {
                                fill: @font-color;
                            }
                        }
                    }
                }



            }
        }
    }
}

// Dashboard Devices Cards
.light__layout {
    .dashBoardWrap {
        .DevicesLayoutWrap {
            .cardContainer {
                background-color: @white;
                border: 1px solid transparent;
                padding: 20px;
                margin-top: 15px;
                // display: flex;
                // flex-wrap: wrap;
                .transition();


                .cardContent {
                    display: flex;
                    align-items: center;

                    .deviceNumber {
                        position: relative;

                        &.green {


                            h1 {
                                color: @green-color;
                                background-color: @light-green-color;
                                .transition();
                            }
                        }

                        &.blue {


                            h1 {
                                color: @blue-color;
                                background-color: @light-blue-color;
                                .transition();
                            }
                        }

                        &.purple {


                            h1 {
                                color: @purple-color;
                                background-color: @light-purple-color;
                                .transition();
                            }
                        }

                        &.pink {


                            h1 {
                                color: @pink-color;
                                background-color: @light-pink-color;
                                .transition();
                            }
                        }

                        h1 {
                            font-size: 28px;
                            width: 80px;
                            height: 80px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            margin-bottom: 0;
                            .boldFont();

                            @media screen and (max-width:460px) {
                                width: 60px;
                                height: 60px;
                                font-size: 33px;
                            }

                        }
                    }

                    .cardHeading {
                        margin-left: 40px;


                        h2 {
                            margin-bottom: 0px;
                            .regulerFont();
                            font-size: 20px;
                            color: @font-color;

                            @media screen and (max-width:460px) {
                                font-size: 18px;
                            }

                            @media screen and (max-width:350px) {
                                font-size: 16px;
                            }
                        }

                        @media screen and (max-width:350px) {
                            margin-left: 20px;
                        }
                    }

                    // h1 {
                    //     // padding-left: 35px;
                    //     font-size: 20px;
                    //     .regulerFont();
                    //     color: @font-color;
                    // }
                }

                @media screen and (max-width:460px) {
                    padding: 15px;
                }

                &:hover {
                    //  box-shadow: 0px 4px 40px 4px #282b3614;
                    box-shadow: 0px 3px 40px 3px rgba(149, 153, 172, 0.29);
                    cursor: pointer;
                }
            }

            .col-6 {
                flex: 0 0 25%;
                max-width: 25%;

                @media screen and (max-width:1200px) {
                    flex: 0 0 50% !important;
                    max-width: 50% !important;
                    
                }

                @media screen and (max-width:600px) {
                    flex: 0 0 100% !important;
                    max-width: 100% !important;
                }
            }

            .dashboardSecond {
                margin-top: 47px;

                .col-4 {
                    flex: 0 0 33.33%;
                    max-width: 33.33%;

                    @media screen and (max-width:1100px) {
                        flex: 0 0 100%;
                        max-width: 100%;
                        margin-top: 10px;
                    }
                }

                .cardContainer {
                    padding: 21px 21px;
                    border: 1px solid transparent;

                    .CardContent {
                        display: flex;
                        align-items: center;

                        .cardIcon {
                            width: 80px;
                            height: 80px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &.green {
                                background-color: @light-green-color;

                            }

                            &.darkYellow {
                                background-color: #fde4bf;
                            }

                            &.lightYellow {
                                background-color: #fbeeca;
                            }

                            @media screen and (max-width:450px) {
                                width: 60px;
                                height: 60px;
                            }
                        }

                        .CardHeading {
                            width: calc(100% - 140px);
                            padding-left: 28px;
                            .regulerFont();
                            color: @font-color;

                            @media screen and (max-width:450px) {
                                width: 100%;
                                padding-left: 0px;
                            }

                            h2 {
                                font-size: 22px;
                                margin-bottom: 0px;
                                position: relative;

                                @media screen and (max-width:450px) {
                                    font-size: 20px;
                                    text-align: center;
                                }

                            }

                            .cardPera {
                                opacity: 0.800;
                                font-size: 15px;
                                line-height: 1.5;
                                color: @font-color;
                                font-weight: 400;
                                font-family: @secondary-font;

                                @media screen and (max-width:450px) {
                                    font-size: 12px;
                                    text-align: center;
                                }
                            }

                            .ant-divider-horizontal {
                                margin: 15px 0px;
                                border-top: 1px solid @input-color;
                            }
                        }

                        @media screen and (max-width:450px) {
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                        }
                    }

                    @media screen and (max-width:450px) {
                        padding: 15px;
                    }

                    .deviceNumber {
                        &.blue {


                            h1 {
                                color: @blue-color;
                                background-color: @light-blue-color;
                            }
                        }

                        &.purple {


                            h1 {
                                color: @purple-color;
                                background-color: @light-purple-color;
                            }
                        }

                        &.pink {


                            h1 {
                                color: @pink-color;
                                background-color: @light-pink-color;
                            }
                        }

                        h1 {
                            font-size: 33px;
                            width: 60px;
                            height: 60px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            margin-bottom: 0;
                            .boldFont();

                            @media screen and (max-width:460px) {
                                width: 40px;
                                height: 40px;
                                font-size: 25px;
                            }
                        }
                    }
                }

            }

            .ant-typography {
                font-size: 18px;
                .regulerFont();
                color: #87847f;
            }

            .ant-alert {
                background-color: #1bb2e729;
                border: 1px solid #e5e5e5;
                border-radius: 3px;

                .ant-alert-content {
                    .ant-alert-message {
                        color: #484e52;
                    }
                }
            }

            .deviceAlert {
                b {
                    color: #1f73aa;
                }

                b {
                    a {
                        color: #1f73aa;

                        &:hover {
                            color: @primary-color;
                        }
                    }
                }
            }

        }

    }

    @media screen and (max-width:1700px) {
        .dashBoardWrap {
            .DevicesLayoutWrap {
                .cardContainer {
                    padding: 15px;

                    .cardContent {
                        .deviceNumber {
                            h1 {
                                width: 70px;
                                height: 70px;
                            }
                        }

                        .cardHeading {
                            margin-left: 20px;

                            h2 {
                                font-size: 18px;
                            }
                        }
                    }
                }

                .dashboardSecond {
                    .cardContainer {
                        padding: 15px;

                        .deviceNumber {
                            h1 {
                                width: 50px;
                                height: 50px;
                                font-size: 22px;
                            }
                        }

                        .CardContent {
                            .CardHeading {
                                padding-left: 18px;
                            }
                        }

                        .CardContent {
                            .CardHeading {
                                width: calc(100% - 110px);

                                h2 {
                                    font-size: 18px;
                                }
                            }

                            .cardIcon {
                                width: 60px;
                                height: 60px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width:1400px) {
        .dashBoardWrap {
            .DevicesLayoutWrap {
                .cardContainer {
                    .cardContent {
                        .cardHeading {
                            margin-left: 15px;

                            h2 {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width:1200px) {
        .dashBoardWrap {
            .DevicesLayoutWrap {
                .dashboardSecond {
                    margin: 20px 0 0;
                    padding-bottom: 20px;

                    .col-4 {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                }
            }
        }
    }

    @media screen and (max-width:960px) {
        .dashBoardWrap {
            .DevicesLayoutWrap {
                .dashboardSecond {
                    .col-4 {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
            }
        }
    }

}


.dark__layout {
    .dashBoardWrap {
        .DevicesLayoutWrap {
            .cardContainer {
                background-color: @layout-sidebar-dark-background;
                padding: 20px;
                margin-top: 15px;
                // display: flex;
                // flex-wrap: wrap;
                border: 1px solid rgba(189, 185, 181, 0.2);
                .transition();


                .cardContent {
                    display: flex;
                    align-items: center;

                    .deviceNumber {
                        position: relative;

                        &.green {


                            h1 {
                                color: @green-color;
                                background-color: rgb(86, 104, 94, 0.5);
                                .transition();
                            }
                        }

                        &.blue {


                            h1 {
                                color: @blue-color;
                                background-color: rgb(97, 113, 124, 0.5);
                                .transition();
                            }
                        }

                        &.purple {


                            h1 {
                                color: @purple-color;
                                background-color: rgb(99, 86, 104, 0.5);
                                .transition();
                            }
                        }


                        &.pink {


                            h1 {
                                color: @pink-color;
                                background-color: rgb(131, 115, 115, 0.5);
                                .transition();
                            }
                        }

                        h1 {
                            font-size: 28px;
                            width: 80px;
                            height: 80px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            margin-bottom: 0;
                            .boldFont();

                            @media screen and (max-width:460px) {
                                width: 60px;
                                height: 60px;
                                font-size: 33px;
                            }

                        }

                        // &:after {
                        //     background-color: #ecfaf2;
                        //     width: 60px;
                        //     height: 60px;
                        //     content: "";
                        //     position: absolute;
                        //     border-radius: 50%;
                        //     left: -8px;
                        //     bottom: -8px;
                        //     z-index: -1;
                        // }

                    }

                    .cardHeading {
                        margin-left: 40px;


                        h2 {
                            margin-bottom: 0px;
                            .regulerFont();
                            font-size: 20px;
                            color: @white;

                            @media screen and (max-width:460px) {
                                font-size: 18px;
                            }

                            @media screen and (max-width:350px) {
                                font-size: 16px;
                            }
                        }

                        @media screen and (max-width:350px) {
                            margin-left: 20px;
                        }
                    }

                    // h1 {
                    //     // padding-left: 35px;
                    //     font-size: 20px;
                    //     .regulerFont();
                    //     color: @font-color;
                    // }
                }

                @media screen and (max-width:1024px) {
                    margin-bottom: 20px;
                }

                @media screen and (max-width:460px) {
                    padding: 15px;
                }

                &:hover {
                    // box-shadow: 0px 4px 40px 4px rgb(40 43 54 / 8%);

                    box-shadow: 0px 3px 12px 1px rgba(116, 117, 118, 0.32);

                    cursor: pointer;
                }
            }

            .col-6 {
                flex: 0 0 25%;
                max-width: 25%;

                @media screen and (max-width:1024px) {
                    flex: 0 0 50% !important;
                    max-width: 50% !important;
                }

                @media screen and (max-width:600px) {
                    flex: 0 0 100% !important;
                    max-width: 100% !important;
                }
            }

            .dashboardSecond {
                margin-top: 47px;

                .col-4 {
                    flex: 0 0 33.33%;
                    max-width: 33.33%;

                    @media screen and (max-width:1024px) {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }

                .cardContainer {
                    padding: 21px 21px;
                    border: 1px solid #414141;

                    .CardContent {
                        display: flex;
                        align-items: center;

                        .cardIcon {
                            width: 80px;
                            height: 80px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &.green {
                                background-color: @light-green-color;

                            }

                            &.darkYellow {
                                background-color: #fde4bf;
                            }

                            &.lightYellow {
                                background-color: #fbeeca;
                            }

                            @media screen and (max-width:450px) {
                                width: 60px;
                                height: 60px;
                            }
                        }

                        .CardHeading {
                            width: calc(100% - 140px);
                            padding-left: 28px;
                            .regulerFont();


                            @media screen and (max-width:450px) {
                                width: 100%;
                                padding-left: 0px;
                            }

                            h2 {
                                font-size: 22px;
                                margin-bottom: 0px;
                                position: relative;
                                color: @white;

                                @media screen and (max-width:450px) {
                                    font-size: 20px;
                                    text-align: center;
                                }

                            }


                            .ant-divider-horizontal {
                                margin: 15px 0px;
                                border-top: 1px solid @input-color;
                            }
                        }

                        @media screen and (max-width:450px) {
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                        }
                    }

                    @media screen and (max-width:450px) {
                        padding: 15px;
                    }

                    .deviceNumber {
                        &.blue {


                            h1 {
                                color: @blue-color;
                                background-color: rgb(97, 113, 124, 0.5);
                            }
                        }

                        &.purple {


                            h1 {
                                color: @purple-color;
                                background-color: rgb(99, 86, 104, 0.5);
                            }
                        }

                        &.pink {


                            h1 {
                                color: @pink-color;
                                background-color: rgb(131, 115, 115, 0.5);
                            }
                        }

                        h1 {
                            font-size: 33px;
                            width: 60px;
                            height: 60px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            margin-bottom: 0;
                            .boldFont();

                            @media screen and (max-width:460px) {
                                width: 40px;
                                height: 40px;
                                font-size: 25px;
                            }
                        }
                    }
                }

            }

            @media screen and(max-width:1200px) {
                .ant-col-6 {
                    flex: 0 0 50%;
                    max-width: 50%;
                    margin-top: 10px;
                }
            }

        }

        .ant-typography {
            font-size: 18px;
            .regulerFont();
            color: #87847f;
        }

        .ant-alert {
            background-color: #1bb2e74f;
            border: 1px solid rgba(189, 185, 181, 0.2);
            border-radius: 3px;

            .ant-alert-content {
                .ant-alert-message {
                    color: #f9fdff;
                }
            }
        }

        .deviceAlert {
            color: #d1d1d1;

            b {
                color: #1f73aa;
            }

            b {
                a {
                    color: #1f73aa;

                    &:hover {
                        color: @primary-color;
                    }
                }
            }
        }

    }

    @media screen and (max-width:1700px) {
        .dashBoardWrap {
            .DevicesLayoutWrap {
                .cardContainer {
                    padding: 15px;

                    .cardContent {
                        .deviceNumber {
                            h1 {
                                width: 70px;
                                height: 70px;
                            }
                        }

                        .cardHeading {
                            margin-left: 20px;

                            h2 {
                                font-size: 18px;
                            }
                        }
                    }
                }

                .dashboardSecond {
                    .cardContainer {
                        padding: 15px;

                        .deviceNumber {
                            h1 {
                                width: 50px;
                                height: 50px;
                                font-size: 22px;
                            }
                        }

                        .CardContent {
                            .CardHeading {
                                padding-left: 18px;
                            }
                        }

                        .CardContent {
                            .CardHeading {
                                width: calc(100% - 110px);

                                h2 {
                                    font-size: 18px;
                                }
                            }

                            .cardIcon {
                                width: 60px;
                                height: 60px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width:1400px) {
        .dashBoardWrap {
            .DevicesLayoutWrap {
                .cardContainer {
                    .cardContent {
                        .cardHeading {
                            margin-left: 15px;

                            h2 {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width:1200px) {
        .dashBoardWrap {
            .DevicesLayoutWrap {
                .dashboardSecond {
                    margin: 20px 0 0;
                    padding-bottom: 20px;

                    .col-4 {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                }

                .col-6 {
                    flex: 0 0 50%;
                    max-width: 50%;
                }
            }
        }
    }

    @media screen and (max-width:960px) {
        .dashBoardWrap {
            .DevicesLayoutWrap {
                .dashboardSecond {
                    .col-4 {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

.dark__layout {
    #layoutPermission {
        .ant-segmented-item {
            color: #fff;
            &.ant-segmented-item-selected{
                color: #000;
            }
        }
    }
}