.light__layout {
	.main__page__appheader {
		display: flex;
		align-items: center;
		padding: 5px 35px;
		height: @layout-header-height;
		line-height: normal;
		background-color: @primary-color;
		.transition();
		box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);

		@media screen and (max-width:400px) {
			padding: 5px 15px;
		}

		.headerWrap {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			@media screen and (max-width:400px) {
				justify-content: flex-start;
				width: auto;
			}
		}

		.appheader__right {
			margin-left: auto;
			position: relative;

			.userDropdown {
				display: flex;
				align-items: center;
				background-color: @white;
				border-radius: 30px;
				margin-right: 20px;
				.transition();

				.userMenuWrap {
					display: flex;
					align-items: center;
					cursor: pointer;

					>span {
						margin-left: 10px;
					}

					.avatar img {
						width: 35px;
						background-color: transparent;
						border-radius: 50%;

						@media screen and (max-width:350px) {
							width: 28px;
							height: 28px;
						}
					}

					.userName {
						.regulerFont();
						font-size: 16px;
						color:@dark-blue-color;
						text-transform: capitalize;
						margin-left: 15px;
						.transition();

						@media screen and (max-width:350px) {
							font-size: 12px;
							margin-left: 5px;
						}
					}

					.anticon {
						font-size: 12px;
					}

					svg {
						margin: 4px 15px 0px 10px;
						path{
							fill:@dark-blue-color;
						}
					}

					.avatar {
						padding: 6px 0px;
					}
				}

				.downIcon {
					transform: rotate(-90deg);
				}

				&:hover {
					.userName {
						color: @font-color;
					}

					.userMenuWrap {
						svg {
							path {
								fill: @font-color;
							}
						}
					}
				}

				@media screen and (max-width:350px) {
					margin-right: 8px;
				}
			}

			.modeIcon {
				.transition();
				cursor: pointer;
				background: #fff;
				// padding: 11px 2px 11px 4px;
				height: 40px;
				width: 40px;
				display: flex;
				align-items: center;
				border-radius: 10px;
				justify-content: center;
				border: 1px solid transparent;
				svg {
					width: 22px;
					height: 22px;
					.transition();

					path {
						fill: @dark-blue-color;

					}
					&:hover {
						path {
							fill: #fff;
						}
					}

					@media screen and (max-width:350px) {
						width: 18px;
						height: 18px;
					}


				}
				&:hover {
					background: transparent;
					border-color: #fff;
                  .darkModeIcon{
				
						path {
							fill: @white;
						}
					
				  }
					
					}

				.darkModeIcon {

					margin: 0px 10px;

				

				}
			}
			&:after {
				position: absolute;
				content: "";
				height: 2px;
				width: 100%;
				background-color:transparent;
				bottom: -12px;
			}
		}

		.appheader__left {
			display: flex;
			align-items: center;

			.menuToggle {
				position: relative;

				.icon {
					width: 35px;
					height: 35px;
					background-color: @white;
					position: absolute;
					border-radius: 3px;
					// left: -52px;
					left: -45px;
					top: -20px;
					// display: flex;
					// align-items: center;
					// justify-content: center;
					// border-radius: 50%;
					z-index: 999;
					cursor: pointer;
					.transition();
					// box-shadow: 10px 10px 19px -13px rgb(255 255 255 / 57%);

					svg {
						.transition();
						margin-left: 4px;
						margin-top: 3px;

						path {
							fill: @light-sidebar-font-color;
						}
					}

					&.right svg {
						-webkit-transform: rotate(180deg);
						transform: rotate(180deg);
					}

					@media screen and (max-width:400px) {
						left: -32px;
						top: -20px;
					}
				}
			}

			>div {
				margin-right: 15px;
			}
		}

		.sidebar__trigger {
			cursor: pointer;
		}


	}

}

.dark__layout {
	.main__page__appheader {
		display: flex;
		align-items: center;
		padding: 5px 35px;
		height: @layout-header-height;
		line-height: normal;
		.transition();
		// background-color: @dark-header-navigation;
		// border-bottom:2px solid re;		

		background-color: @dark-layout-bg;
		box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);

		@media screen and (max-width:400px) {
			padding: 5px 15px;
		}

		.headerWrap {
			width: 100%;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;

			@media screen and (max-width:400px) {
				justify-content: flex-start;
				width: auto;
			}

			.appheader__right {
				margin-left: auto;

				.userDropdown {
					display: flex;
					align-items: center;
					background-color: #444a4f;
					border-radius: 30px;
					margin-right: 20px;
					.transition();

					.userMenuWrap {
						display: flex;
						align-items: center;
						cursor: pointer;

						>span {
							margin-left: 10px;
						}

						.avatar img {
							width: 35px;
							height: 35px;
							background-color: transparent;
							border-radius: 50%;

							@media screen and (max-width:350px) {
								width: 28px;
								height: 28px;
							}
						}

						.userName {
							.regulerFont();
							font-size: 16px;
							color: @white;
							text-transform: capitalize;
							margin-left: 15px;
							.transition();

							@media screen and (max-width:350px) {
								font-size: 12px;
								margin-left: 5px;
							}
						}

						.anticon {
							font-size: 12px;
						}

						svg {
							margin: 4px 15px 0px 10px;

							path {
								fill: @white;
							}

						}

						.avatar {
							padding: 6px 0px;
						}
					}

					.downIcon {
						transform: rotate(-90deg);
					}

					&:hover {
						.userName {
							color: @primary-color;
						}

						.userMenuWrap {
							svg {
								path {
									fill: @primary-color;
								}
							}
						}
					}

					@media screen and (max-width:350px) {
						margin-right: 8px;
					}
				}

				.modeIcon {
					.transition();
					cursor: pointer;
					background: #444a4f;
					// padding: 11px 15px;
					height: 40px;
					width: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 10px;

					svg {
						width: 22px;
						height: 22px;
						.transition();

						path {
							fill: @primary-color;

						}



						@media screen and (max-width:350px) {
							width: 18px;
							height: 18px;
						}

						&.lightModeIcon {
							&:hover {
								path {
									fill: @primary-color;
								}
							}
						}




					}
					&:hover {
					background:transparent;
					border:1px solid @primary-color;
					}

					.darkModeIcon {
						margin: 0px 10px;


					}
				}
			}

			&:after {
				position: absolute;
				content: "";
				height: 1px;
				width: 100%;
			    background-color: rgba(189, 185, 181,0.2);
				bottom: -12px;
			}
		}



		.appheader__left {
			display: flex;
			align-items: center;

			.menuToggle {
				position: relative;

				.icon {
					width: 35px;
					height: 35px;
					background-color: @dark-layout-bg;
					.transition();
					position: absolute;
					border-radius: 3px;
					// left: -52px;
					left: -45px;
					top: -20px;
					// display: flex;
					// align-items: center;
					// justify-content: center;
					// border-radius: 50%;
					z-index: 999;
					cursor: pointer;
					// box-shadow: 10px 10px 19px -13px rgb(255 255 255 / 57%);

					svg {
						.transition();
						margin-left: 4px;
						margin-top: 3px;

						path {
							fill: @white;
						}
					}

					&.right svg {
						-webkit-transform: rotate(180deg);
						transform: rotate(180deg);
					}

					@media screen and (max-width:400px) {
						left: -32px;
						top: -20px;
					}
				}
			}

			>div {
				margin-right: 15px;
			}
		}

		.sidebar__trigger {
			cursor: pointer;
		}


	}

}
